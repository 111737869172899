import React from 'react';
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import { Edit, Mail, Trash } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const UserColumns = (handleDeleteClick) => {
    const navigate = useNavigate();

    return [
        {
            name: <span className='font-weight-bold fs-13'>Nome</span>,
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: <span className='font-weight-bold fs-13'>Email</span>,
            selector: row => row.email,
            sortable: true,
            grow: 2
        },
        {
            name: <span className='font-weight-bold fs-13'>Ruolo</span>,
            selector: row => row.role,
            sortable: true,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Stato</span>,
            selector: row => row.is_active ? 'Attivo' : 'Inattivo',
            sortable: true,
            width: '120px',
            cell: row => (
                <Badge color={row.is_active ? 'success' : 'warning'} pill className="px-3 py-2">
                    {row.is_active ? 'Attivo' : 'Inattivo'}
                </Badge>
            ),
        },
        {
            name: <span className='font-weight-bold fs-13'>Azioni</span>,
            cell: row => (
                <div className="d-flex" style={{ gap: '2px' }}>
                    <Button
                        id={`editBtn-${row.id}`}
                        color="info"
                        size="sm"
                        className="p-1"
                        style={{ width: '28px', height: '28px' }}
                        onClick={() => navigate(`/admin-users/${row.id}/edit`)}
                    >
                        <Edit size={14} />
                    </Button>
                    <UncontrolledTooltip target={`editBtn-${row.id}`}>
                        Modifica
                    </UncontrolledTooltip>

                    <Button
                        id={`emailBtn-${row.id}`}
                        color="primary"
                        size="sm"
                        className="p-1"
                        style={{ width: '28px', height: '28px' }}
                        onClick={() => {/* Funzione per inviare email */}}
                    >
                        <Mail size={14} />
                    </Button>
                    <UncontrolledTooltip target={`emailBtn-${row.id}`}>
                        Invia Email
                    </UncontrolledTooltip>

                    <Button
                        id={`deleteBtn-${row.id}`}
                        color="danger"
                        size="sm"
                        className="p-1"
                        style={{ width: '28px', height: '28px' }}
                        onClick={() => handleDeleteClick(row)}
                    >
                        <Trash size={14} />
                    </Button>
                    <UncontrolledTooltip target={`deleteBtn-${row.id}`}>
                        Elimina
                    </UncontrolledTooltip>
                </div>
            ),
            width: '120px',
            right: true
        },
    ];
};

export default UserColumns;
