import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Table, Alert } from 'reactstrap';
import { Network, Plus, Pencil, Trash2 } from 'lucide-react';
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import apiCalls from "../../contexts/apiCall";

const NameserverPackageList = () => {
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [editingPackage, setEditingPackage] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        nameservers: [
            { priority: "", fqdn: "", ip_address: "" },
            { priority: "", fqdn: "", ip_address: "" }
        ]
    });
    const [formError, setFormError] = useState("");

    const fetchPackages = async () => {
        setLoading(true);
        try {
            const response = await apiCalls.settingApi.listNameserverPack();
            setPackages(response);
        } catch (err) {
            setError("Errore durante il caricamento dei pacchetti.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm("Sei sicuro di voler eliminare questo pacchetto?")) {
            try {
                const data = { id: id };
                await apiCalls.settingApi.deleteNameserverPack(data);
                setSuccess("Pacchetto eliminato con successo!");
                fetchPackages();
            } catch (err) {
                setError("Errore durante l'eliminazione del pacchetto.");
                console.error("Error:", err);
            }
        }
    };

    const resetForm = () => {
        setFormData({
            name: "",
            nameservers: [
                { priority: "", fqdn: "", ip_address: "" },
                { priority: "", fqdn: "", ip_address: "" }
            ]
        });
        setFormError("");
        setEditingPackage(null);
    };

    const openModal = (pkg = null) => {
        if (pkg) {
            setFormData({
                name: pkg.name,
                nameservers: [...pkg.nameservers]
            });
            setEditingPackage(pkg);
        } else {
            resetForm();
        }
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        resetForm();
    };

    const handleNameChange = (e) => {
        setFormData({
            ...formData,
            name: e.target.value
        });
    };

    const handleNameserverChange = (index, field, value) => {
        const newNameservers = formData.nameservers.map((ns, i) => {
            if (i === index) {
                return { ...ns, [field]: value };
            }
            return ns;
        });

        setFormData({
            ...formData,
            nameservers: newNameservers
        });
    };

    const addNameserver = () => {
        if (formData.nameservers.length >= 5) {
            setFormError("Non puoi aggiungere più di 5 nameserver");
            return;
        }
        setFormData({
            ...formData,
            nameservers: [...formData.nameservers, { priority: "", fqdn: "", ip_address: "" }]
        });
    };

    const removeNameserver = (index) => {
        if (formData.nameservers.length <= 2) {
            setFormError("Devi mantenere almeno 2 nameserver");
            return;
        }
        const newNameservers = formData.nameservers.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            nameservers: newNameservers
        });
        setFormError("");
    };

    const validateForm = () => {
        if (!formData.name.trim()) {
            setFormError("Il nome del pacchetto è obbligatorio");
            return false;
        }

        if (formData.nameservers.length < 2) {
            setFormError("Devi inserire almeno 2 nameserver");
            return false;
        }

        if (formData.nameservers.length > 5) {
            setFormError("Non puoi inserire più di 5 nameserver");
            return false;
        }

        for (const ns of formData.nameservers) {
            if (!ns.fqdn.trim()) {
                setFormError("Tutti i campi FQDN sono obbligatori");
                return false;
            }
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        setFormError("");

        try {
            if (editingPackage) {
                const updateData = {
                    id: editingPackage.id,
                    ...formData
                };
                await apiCalls.settingApi.updateNameserverPack(updateData);
            } else {
                await apiCalls.settingApi.storeNameserverPack(formData);
            }
            setSuccess(`Pacchetto ${editingPackage ? "modificato" : "creato"} con successo!`);
            closeModal();
            fetchPackages();
        } catch (err) {
            setFormError("Errore durante il salvataggio del pacchetto. Riprova più tardi.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            name: "Nome Pacchetto",
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: "Nameservers",
            selector: row => row.nameservers.length,
            sortable: true,
            cell: row => (
                <div>
                    {row.nameservers.map((ns, index) => (
                        <div key={index} className="small text-muted">
                            {ns.fqdn} {ns.ip_address ? `(${ns.ip_address})` : ""}
                        </div>
                    ))}
                </div>
            )
        },
        {
            name: "Azioni",
            cell: row => (
                <div>
                    <Button
                        color="info"
                        size="sm"
                        className="me-2"
                        onClick={() => openModal(row)}
                    >
                        <Pencil size={16} />
                    </Button>
                    <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDelete(row.id)}
                    >
                        <Trash2 size={16} />
                    </Button>
                </div>
            ),
            button: true,
            width: "150px"
        }
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: "72px",
            }
        },
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                backgroundColor: "#f8f9fa",
                fontWeight: "bold"
            },
        },
        cells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
            },
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Nameserver" breadcrumbItem="Lista Pacchetti" />

                    {error && (
                        <Alert color="danger" toggle={() => setError("")}>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert color="success" toggle={() => setSuccess("")}>
                            {success}
                        </Alert>
                    )}

                    <Row>
                        <Col lg={12}>
                            <Card className="shadow-sm">
                                <CardBody>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4 className="card-title mb-0">
                                            <Network className="me-2" size={24} />
                                            Pacchetti Nameserver
                                        </h4>
                                        <Button color="primary" onClick={() => openModal()}>
                                            <Plus size={16} className="me-1" />
                                            Nuovo Pacchetto
                                        </Button>
                                    </div>

                                    <DataTable
                                        columns={columns}
                                        data={packages}
                                        progressPending={loading}
                                        pagination
                                        customStyles={customStyles}
                                        noDataComponent={
                                            <div className="p-4">
                                                Nessun pacchetto nameserver trovato
                                            </div>
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Modal Form */}
                    <Modal isOpen={modalOpen} toggle={closeModal} size="lg">
                        <ModalHeader toggle={closeModal}>
                            {editingPackage ? "Modifica Pacchetto Nameserver" : "Nuovo Pacchetto Nameserver"}
                        </ModalHeader>
                        <Form onSubmit={handleSubmit}>
                            <ModalBody>
                                {formError && (
                                    <Alert color="danger">
                                        {formError}
                                    </Alert>
                                )}

                                <FormGroup className="mb-4">
                                    <Label for="packageName" className="text-muted">Nome del Pacchetto</Label>
                                    <Input
                                        type="text"
                                        name="packageName"
                                        id="packageName"
                                        placeholder="es. Default Whiteready"
                                        value={formData.name}
                                        onChange={handleNameChange}
                                        required
                                    />
                                </FormGroup>

                                <div className="mb-3">
                                    <Label className="text-muted">Nameserver</Label>
                                    <div className="table-responsive">
                                        <Table className="mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th>Priorità</th>
                                                <th>FQDN</th>
                                                <th>Indirizzo IP</th>
                                                <th>Azioni</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {formData.nameservers.map((ns, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="es. 10"
                                                            value={ns.priority}
                                                            onChange={(e) => handleNameserverChange(index, "priority", e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="es. ns1.esempio.com"
                                                            value={ns.fqdn}
                                                            onChange={(e) => handleNameserverChange(index, "fqdn", e.target.value)}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="es. 192.0.2.1"
                                                            value={ns.ip_address}
                                                            onChange={(e) => handleNameserverChange(index, "ip_address", e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            onClick={() => removeNameserver(index)}
                                                            disabled={formData.nameservers.length <= 2}
                                                        >
                                                            <Trash2 size={16} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={addNameserver}
                                    disabled={formData.nameservers.length >= 5}
                                    className="mt-2"
                                >
                                    <Plus size={16} className="me-1" /> Aggiungi Nameserver
                                </Button>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="light" onClick={closeModal}>
                                    Annulla
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Salvataggio..." : "Salva"}
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default NameserverPackageList;
