import styled from 'styled-components';
import {Badge, Card, Button} from "reactstrap";

const StyledCard = styled(Card)`
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    }
`;

const StyledBadge = styled(Badge)`
    font-size: 0.8rem;
    padding: 0.5em 0.8em;
`;

const StyledButton = styled(Button)`
    transition: all 0.2s ease;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    }
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 15px;
    margin-bottom: 20px;
`;

const FilterGroup = styled.div`
  flex: 1;
`;

const SummaryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
    margin-top: 20px;
`;

const SummaryItem = styled.div`
    text-align: center;
`;

const SummaryLabel = styled.div`
    font-size: 0.9rem;
    color: #6c757d;
`;

const SummaryValue = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    color: #495057;
`;

export {
    StyledCard,
    StyledBadge,
    StyledButton,
    FilterContainer,
    FilterGroup,
    SummaryContainer,
    SummaryItem,
    SummaryLabel,
    SummaryValue,
}
