const transformDomainData = (currentData) => {
    try {
        // Basic domain information
        return {
            nome_dominio: `${currentData.domain_name}.${currentData.tld}`,
            periodo_sottoscrizione: parseInt(currentData.subscription_period) || 1,
            modalita_rinnovo: currentData.renewal_mode === 'autorenew' ? 'auto' : 'manual',
            id_cartella: currentData.folder_id || '',
            id_profilo_nameserver: currentData.nameserver_profile_id || '',
            id_profilo_zona: currentData.zone_profile_id || '',

            // Transform nameservers
            nameserver: currentData.nameservers.map((ns, index) => ({
                priorita: ns.priority || (index + 1),
                fqdn: ns.fqdn || '',
                indirizzo_ip: ns.ip_address || ''
            })).filter(ns => ns.fqdn),

            // Transform contacts
            contatti: {
                admin: transformContact(currentData.contacts.admin),
                billing: transformContact(currentData.contacts.billing),
                tech: transformContact(currentData.contacts.tech),
                org: transformContact(currentData.contacts.org)
            },

            privacy_whois: currentData.whois_privacy,
            accetta_termini_registrazione: currentData.accept_registration_terms ? 'Yes' : 'No'
        };
    } catch (error) {
        console.error('Error transforming domain data:', error);
        throw new Error(`Failed to transform domain data: ${error.message}`);
    }
};

// Helper function to transform contact information
const transformContact = (contact) => {
    if (!contact) return null;

    return {
        id_profilo: contact.contact_id || '',
        nome: (contact.firstname || '').trim(),
        cognome: (contact.lastname || '').trim(),
        azienda: (contact.company || '').trim(),
        indirizzo1: (contact.street || '').trim(),
        citta: (contact.city || '').trim(),
        cap: (contact.postal_code || '').trim(),
        codice_paese: (contact.country_code || '').trim(),
        email: (contact.email || '').trim(),
        telefono: (contact.voice || '').trim(),
        fax: (contact.fax || '').trim()
    };
};

exports.transformDomainData = transformDomainData;
