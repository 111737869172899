import React, {createContext, useState, useContext, useEffect} from 'react';
import apiCall from "./apiCall";


const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkUser();
    }, []);

    const checkUser = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await apiCall.auth.checkUser();
                setUser(response.user);
            } catch (error) {
                console.error('Failed to fetch user:', error);
                localStorage.removeItem('token');
                setUser(null);
            }
        } else {
            setUser(null);
        }
        setLoading(false);
    };

    const login = async (email, password) => {
        try {
            const response = await apiCall.auth.login(email, password);

            if (response.status === 200) {
                const { access_token, expires_at, user } = response.data;

                // Salva il token nel localStorage
                localStorage.setItem('token', access_token);

                // Converte 'expires_at' in millisecondi (se è in secondi)
                localStorage.setItem('expiresAt', expires_at * 1000);

                // Salva le informazioni dell'utente nel localStorage
                localStorage.setItem('authUser', JSON.stringify(user));

                // Imposta l'utente nello stato di React (assumendo che user venga da response.data.user)
                setUser(user);

                return {
                    stato: 'ok',
                    json: () => Promise.resolve(response.data),
                };
            } else {
                return {
                    stato: 'invalid_credentials',
                    json: () => Promise.resolve(response.data),
                };
            }
        } catch (error) {
            console.error('Login failed:', error);
            return {
                stato: 'error',
                json: () => Promise.resolve({ error: 'Login failed' }),
            };
        }
    };


    const register = async (name, email, password) => {
        try {
            const response = await apiCall.auth.register(
                name,
                email,
                password
            );
            localStorage.setItem('token', response.data.access_token);
            setUser(response.data.user);
            return true;
        } catch (error) {
            console.error('Registration failed:', error);
            return false;
        }
    };

    const logout = () => {
        // Rimuovi il token dal localStorage
        localStorage.removeItem('token');

        // Rimuovi altri dati relativi all'autenticazione, se presenti
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('authUser');

        // Resetta lo stato dell'utente
        setUser(null);

        // Opzionale: puoi anche reindirizzare l'utente alla pagina di login qui
        // history.push('/login'); // Se stai usando react-router

        // Opzionale: puoi anche emettere un evento personalizzato per notificare altre parti dell'app
        // window.dispatchEvent(new Event('userLoggedOut'));

        // console.log('Logout effettuato con successo');
    };

    const value = {
        user,
        login,
        register,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
