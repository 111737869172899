import React, { useEffect, useState } from 'react';
import { Card, CardBody, Progress, Spinner, Alert } from "reactstrap";
import { Globe, Check, AlertTriangle, XOctagon, Repeat, Clock } from 'lucide-react';
import apiCalls from "../../../../contexts/apiCall";

const iconMap = { Globe, Check, AlertTriangle, XOctagon, Repeat, Clock };

const DomainOverviewClienti = () => {
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiCalls.dashboardDataApi.overviewDominiClienti();
                if (response?.original?.data?.overview) {
                    setStats(response.original.data.overview.map(item => ({
                        ...item,
                        icon: iconMap[item.icon] || Globe
                    })));
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
            } catch (err) {
                console.error("Error fetching overview data:", err);
                setError("Errore nel caricamento dei dati");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center" style={{ minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner color="primary" />
                </div>
            );
        }

        if (error || stats.length === 0) {
            return (
                <Alert color="danger">
                    Errore nel caricamento dei dati. Riprovare più tardi.
                </Alert>
            );
        }

        return (
            <div className="table-responsive">
                <table className="table table-sm table-hover table-nowrap mb-0">
                    <thead className="table-light">
                    <tr>
                        <th scope="col">Stato</th>
                        <th scope="col">Conteggio</th>
                        <th scope="col">%</th>
                        <th scope="col" style={{width: "30%"}}>Progresso</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stats.map((stat, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex align-items-center m-2">
                                    <div className={`avatar-xs me-2 bg-${stat.color}-subtle rounded`}>
                                            <span className={`avatar-title rounded-circle bg-${stat.color}-subtle text-${stat.color} font-size-14`}>
                                                {stat.icon && <stat.icon size={12} />}
                                            </span>
                                    </div>
                                    <span className="font-size-13">{stat.title}</span>
                                </div>
                            </td>
                            <td>{stat.count}</td>
                            <td>{stat.percentage?.toFixed(1)}%</td>
                            <td>
                                <Progress value={stat.percentage} color={stat.color} style={{height: "4px"}} />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <Card>
            <CardBody>
                <h5 className="card-title mb-3">Panoramica dei Domini</h5>
                {renderContent()}
            </CardBody>
        </Card>
    );
};

export default DomainOverviewClienti;
