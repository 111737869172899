import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { RefreshCw, Edit, Lock, Pause, ExternalLink, Info } from 'lucide-react';

export const getDomainTableColumns = (handleAction, handleViewDetails, formatDate) => [
    {
        name: <span className='font-weight-bold fs-13'>Nome Dominio</span>,
        cell: row => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{row.name}</span>
                <Button
                    color="link"
                    className="p-0 ms-2"
                    onClick={() => window.open(`http://${row.name}`, '_blank')}
                >
                    <ExternalLink size={14} />
                </Button>
            </div>
        ),
        sortable: true
    },
    {
        name: <span className='font-weight-bold fs-13'>Stato</span>,
        sortable: true,
        cell: (row) => {
            let badgeClass = "";
            switch (row.status_code) {
                case '801':
                    badgeClass = "bg-warning";
                    break;
                case '901':
                    badgeClass = "bg-info";
                    break;
                case '1000':
                    badgeClass = "bg-success";
                    break;
                case '1001':
                    badgeClass = "bg-danger";
                    break;
                default:
                    badgeClass = "bg-secondary";
            }
            return <span className={`badge ${badgeClass}`}>{row.status_description}</span>;
        },
    },
    {
        name: <span className='font-weight-bold fs-13'>STATO DI BLOCCO</span>,
        sortable: true,
        cell: (row) => {
            const getBadgeClass = (lock_status) => {
                switch (lock_status) {
                    case 'locked':
                        return 'bg-danger';
                    case 'pending lock':
                        return 'bg-warning';
                    case 'pending unlock':
                        return 'bg-info';
                    case 'unlocked':
                        return 'bg-success';
                    default:
                        return 'bg-secondary';
                }
            };

            const translateLockStatus = (lock_status) => {
                switch (lock_status) {
                    case 'locked':
                        return 'Bloccato';
                    case 'pending lock':
                        return 'Blocco in sospeso';
                    case 'pending unlock':
                        return 'Sblocco in sospeso';
                    case 'unlocked':
                        return 'Sbloccato';
                    default:
                        return lock_status;
                }
            };

            const badgeClass = getBadgeClass(row.lock_status);
            const translatedStatus = translateLockStatus(row.lock_status);

            return (
                <span className={`badge ${badgeClass}`}>
                    {translatedStatus}
                </span>
            );
        },
    },
    {
        name: <span className='font-weight-bold fs-13'>Data di Scadenza</span>,
        selector: row => formatDate(row.expires_at),
        sortable: true
    },
    {
        name: <span className='font-weight-bold fs-13'>Rinnovo Automatico</span>,
        selector: row => row.renewal_mode === 'autoRenew' ? 'Sì' : 'No',
        sortable: true
    },
    {
        name: <span className='font-weight-bold fs-13'>Azioni</span>,
        cell: (row) => (
            <div>
                <Button id={`updateBtn-${row.id}`} color="primary" size="sm" className="me-1" onClick={() => handleAction(row, 'update')}>
                    <RefreshCw size={14} />
                </Button>
                <UncontrolledTooltip target={`updateBtn-${row.id}`}>Aggiorna</UncontrolledTooltip>

                <Button id={`editBtn-${row.id}`} color="info" size="sm" className="me-1" onClick={() => handleAction(row, 'edit')}>
                    <Edit size={14} />
                </Button>
                <UncontrolledTooltip target={`editBtn-${row.id}`}>Modifica</UncontrolledTooltip>

                <Button id={`lockBtn-${row.id}`} color="warning" size="sm" className="me-1" onClick={() => handleAction(row, 'lock')}>
                    <Lock size={14} />
                </Button>
                <UncontrolledTooltip target={`lockBtn-${row.id}`}>Blocca/Sblocca</UncontrolledTooltip>

                <Button id={`suspendBtn-${row.id}`} color="danger" size="sm" className="me-1" onClick={() => handleAction(row, 'suspend')}>
                    <Pause size={14} />
                </Button>
                <UncontrolledTooltip target={`suspendBtn-${row.id}`}>Sospendi</UncontrolledTooltip>

                <Button id={`detailsBtn-${row.id}`} color="secondary" size="sm" onClick={() => handleViewDetails(row)}>
                    <Info size={14} />
                </Button>
                <UncontrolledTooltip target={`detailsBtn-${row.id}`}>Dettagli</UncontrolledTooltip>
            </div>
        ),
    }
];
