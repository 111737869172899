import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { Plus, Trash2, Shield } from 'lucide-react';
import styled from 'styled-components';

const StyledInput = styled(Input)`
    border-radius: 8px;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

const IconWrapper = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: #007bff;
`;

const DNSSECForm = ({ dnssecData, onChange }) => {
    const handleDNSSECChange = (index, field, value) => {
        const updatedDNSSEC = dnssecData.map((entry, i) =>
            i === index ? { ...entry, [field]: value } : entry
        );
        onChange(updatedDNSSEC);
    };

    const addDNSSECEntry = () => {
        onChange([...dnssecData, { flag: '', protocol: '', algorithm: '', publicKey: '' }]);
    };

    const removeDNSSECEntry = (index) => {
        const updatedDNSSEC = dnssecData.filter((_, i) => i !== index);
        onChange(updatedDNSSEC);
    };

    return (
        <div>
            <h5 className="mb-4">
                <IconWrapper><Shield size={18} /></IconWrapper>
                Impostazioni DNSSEC
            </h5>
            {dnssecData.map((entry, index) => (
                <Row key={index} className="mb-3 align-items-end">
                    <Col md={2}>
                        <FormGroup>
                            <Label for={`dnssec-flag-${index}`}>Flag</Label>
                            <StyledInput
                                type="number"
                                id={`dnssec-flag-${index}`}
                                value={entry.flag}
                                onChange={(e) => handleDNSSECChange(index, 'flag', e.target.value)}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for={`dnssec-protocol-${index}`}>Protocollo</Label>
                            <StyledInput
                                type="number"
                                id={`dnssec-protocol-${index}`}
                                value={entry.protocol}
                                onChange={(e) => handleDNSSECChange(index, 'protocol', e.target.value)}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for={`dnssec-algorithm-${index}`}>Algoritmo</Label>
                            <StyledInput
                                type="number"
                                id={`dnssec-algorithm-${index}`}
                                value={entry.algorithm}
                                onChange={(e) => handleDNSSECChange(index, 'algorithm', e.target.value)}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <Label for={`dnssec-publicKey-${index}`}>Chiave Pubblica</Label>
                            <StyledInput
                                type="text"
                                id={`dnssec-publicKey-${index}`}
                                value={entry.publicKey}
                                onChange={(e) => handleDNSSECChange(index, 'publicKey', e.target.value)}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <Button color="danger" onClick={() => removeDNSSECEntry(index)}>
                            <Trash2 size={16} />
                        </Button>
                    </Col>
                </Row>
            ))}
            <Button disabled color="secondary" onClick={addDNSSECEntry}>
                <Plus size={16} className="me-2" /> Aggiungi Voce DNSSEC
            </Button>
        </div>
    );
};

export default DNSSECForm;
