import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import apiCalls from "../../../../contexts/apiCall";
import DomainTable from './components/DomainTable';
import { useLocation, useNavigate } from 'react-router-dom';

const DomainManagement = () => {
    const [domains, setDomains] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filters, setFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = parseInt(params.get('page')) || parseInt(localStorage.getItem('domainCurrentPage')) || 1;
        const savedFilters = JSON.parse(localStorage.getItem('domainFilters')) || {};
        const savedPerPage = parseInt(localStorage.getItem('domainPerPage')) || 10;

        setCurrentPage(page);
        setFilters(savedFilters);
        setPerPage(savedPerPage);

        fetchDomains(page, savedPerPage, savedFilters);
    }, [location.search]);

    const fetchDomains = async (page, perPage, filters) => {
        setLoading(true);
        try {
            const response = await apiCalls.dominiActionsApi.listDomini({
                page,
                per_page: perPage,
                ...filters
            });
            setDomains(response.data);
            setTotalRows(response.total);
            setLoading(false);
        } catch (error) {
            console.error('Errore nel recupero dei domini:', error);
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem('domainCurrentPage', page.toString());
        navigate(`?page=${page}`);
        fetchDomains(page, perPage, filters);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        localStorage.setItem('domainPerPage', newPerPage.toString());
        fetchDomains(page, newPerPage, filters);
    };

    const handleFilterChange = (newFilters) => {
        const updatedFilters = { ...filters, ...newFilters };
        setFilters(updatedFilters);
        localStorage.setItem('domainFilters', JSON.stringify(updatedFilters));
        setCurrentPage(1);
        localStorage.setItem('domainCurrentPage', '1');
        fetchDomains(1, perPage, updatedFilters);
        navigate('?page=1');
    };

    const formatDate = useCallback((dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
    }, []);

    const isExpiringWithinTwoMonths = useCallback((expirationDate) => {
        if (!expirationDate) return false;
        const today = new Date();
        const expDate = new Date(expirationDate);
        const twoMonthsFromNow = new Date(today.setMonth(today.getMonth() + 2));
        return expDate <= twoMonthsFromNow;
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Domini" breadcrumbItem="Gestione Domini" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">I tuoi domini</h4>
                                    <DomainTable
                                        domains={domains}
                                        totalRows={totalRows}
                                        perPage={perPage}
                                        loading={loading}
                                        onPageChange={handlePageChange}
                                        onPerRowsChange={handlePerRowsChange}
                                        onFilterChange={handleFilterChange}
                                        filters={filters}
                                        formatDate={formatDate}
                                        isExpiringWithinTwoMonths={isExpiringWithinTwoMonths}
                                        currentPage={currentPage}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DomainManagement;
