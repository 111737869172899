import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { Alert, Card, CardBody, CardTitle, Spinner, ButtonGroup, Button, Badge } from "reactstrap";
import { Calendar, Clock, AlertCircle } from 'lucide-react';
import apiCalls from "../../../../contexts/apiCall";

const RenewalNotificationsNoAutoRenewalClienti = () => {
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('30');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await apiCalls.dashboardDataApi.notificheRinnoviNoRenewalClienti();
                // console.log(data.original.data);
                if (Array.isArray(data.original.data.notifications) && data.original.data.notifications.length > 0) {
                    const validNotifications = data.original.data.notifications.filter(item => parseInt(item.time) > 0);
                    setNotifications(validNotifications);
                    filterNotifications('30', validNotifications);
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
                setError(null);
            } catch (err) {
                console.error("Error fetching renewal notifications:", err);
                setError("Errore nel caricamento dei dati");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        filterNotifications(filter, notifications);
    }, [filter, notifications]);

    const filterNotifications = (selectedFilter, notificationsToFilter) => {
        let filtered;
        switch (selectedFilter) {
            case '30':
                filtered = notificationsToFilter.filter(item => parseInt(item.time) > 0 && parseInt(item.time) <= 30);
                break;
            case '60':
                filtered = notificationsToFilter.filter(item => parseInt(item.time) > 30 && parseInt(item.time) <= 60);
                break;
            case 'all':
            default:
                filtered = notificationsToFilter.filter(item => parseInt(item.time) > 0);
                break;
        }
        setFilteredNotifications(filtered);
    };

    const getStatusColor = (days) => {
        if (days <= 30) return "danger";
        if (days <= 60) return "warning";
        return "info";
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center" style={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                        <p className="mt-2">Caricamento in corso...</p>
                    </div>
                </div>
            );
        }

        if (error || filteredNotifications.length === 0) {
            return (
                <Alert color="danger" className="d-flex align-items-center">
                    <AlertCircle size={24} className="me-2" />
                    <div>
                        {error || "Nessuna notifica trovata per il periodo selezionato."}
                    </div>
                </Alert>
            );
        }

        return (
            <>
                <CardTitle className="mb-4 d-flex justify-content-between align-items-center">
                    <span className="h4 mb-0">Notifiche Rinnovi senza Autorinnovo</span>
                    <Badge color="primary" pill>{filteredNotifications.length}</Badge>
                </CardTitle>
                <ButtonGroup className="mb-4 w-100">
                    <Button color="primary" outline onClick={() => setFilter('all')} active={filter === 'all'}>
                        Tutti
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('30')} active={filter === '30'}>
                        30
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('60')} active={filter === '60'}>
                        60
                    </Button>
                </ButtonGroup>
                <SimpleBar style={{ maxHeight: "305px" }}>
                    {filteredNotifications.map((item, key) => (
                        <Card key={key} className="mb-3 shadow-sm">
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div className={`bg-soft-${getStatusColor(parseInt(item.time))} rounded-circle p-2 me-3`}>
                                            <Calendar size={24} className={`text-${getStatusColor(parseInt(item.time))}`} />
                                        </div>
                                        <div>
                                            <h5 className="mb-1">{item.name}</h5>
                                            <p className="text-muted mb-0">{item.desc}</p>
                                        </div>
                                    </div>
                                    <Badge color={getStatusColor(parseInt(item.time))} pill className="d-flex align-items-center">
                                        <Clock size={14} className="me-1" />
                                        {item.time}
                                    </Badge>
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </SimpleBar>
            </>
        );
    };

    return (
        <Card className="shadow border-0">
            <CardBody>
                {renderContent()}
            </CardBody>
        </Card>
    );
};

export default RenewalNotificationsNoAutoRenewalClienti;
