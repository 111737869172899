import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { Alert, Card, CardBody, CardTitle, Spinner, ButtonGroup, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form } from "reactstrap";
import { FileText, AlertCircle, User, Calendar } from 'lucide-react';
import apiCalls from "../../../../contexts/apiCall";
import moment from 'moment';

const ClientRequestsClienti = () => {
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('all');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await apiCalls.clientRequestApi.listRequestRoleClienti();
                if (Array.isArray(data.original) && data.original.length > 0) {
                    setRequests(data.original);
                    filterRequests('all', data.original);
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
                setError(null);
            } catch (err) {
                console.error("Error fetching client requests:", err);
                setError("Errore nel caricamento delle richieste");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        filterRequests(filter, requests);
    }, [filter, requests]);

    const filterRequests = (selectedFilter, requestsToFilter) => {
        if (selectedFilter === 'all') {
            setFilteredRequests(requestsToFilter);
        } else {
            const filtered = requestsToFilter.filter(item => item.status === selectedFilter);
            setFilteredRequests(filtered);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending': return "warning";
            case 'processing': return "info";
            case 'completed': return "success";
            case 'failed': return "danger";
            default: return "secondary";
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center" style={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                        <p className="mt-2">Caricamento in corso...</p>
                    </div>
                </div>
            );
        }

        return (
            <>
                <CardTitle className="mb-4 d-flex justify-content-between align-items-center">
                    <span className="h4 mb-0">Richieste fatte all' Admin</span>
                    <Badge color="primary" pill>{filteredRequests.length}</Badge>
                </CardTitle>
                <ButtonGroup className="mb-4 w-100">
                    <Button color="primary" outline onClick={() => setFilter('all')} active={filter === 'all'}>
                        Tutte
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('pending')} active={filter === 'pending'}>
                        In Attesa
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('processing')} active={filter === 'processing'}>
                        In Elaborazione
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('completed')} active={filter === 'completed'}>
                        Completate
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('failed')} active={filter === 'failed'}>
                        Fallite
                    </Button>
                </ButtonGroup>
                <SimpleBar style={{ maxHeight: "305px" }}>
                    {filteredRequests.length > 0 ? (
                        filteredRequests.map((item, key) => (
                            <Card key={key} className="mb-3 shadow-sm">
                                <CardBody>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div className={`bg-soft-${getStatusColor(item.status)} rounded-circle p-2 me-3`}>
                                                <FileText size={24} className={`text-${getStatusColor(item.status)}`} />
                                            </div>
                                            <div>
                                                <h5 className="mb-1">{item.request_type}</h5>
                                                <p className="text-muted mb-0">{item.domain_name}</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <Badge color={getStatusColor(item.status)} pill className="mb-2">{item.status}</Badge>
                                            <div className="text-muted small">
                                                <User size={14} className="me-1" />{item.request_data.admin_name}
                                            </div>
                                            {item.completed_at && (
                                                <div className="text-muted small mt-1">
                                                    <Calendar size={14} className="me-1" />
                                                    Completata il: {moment(item.completed_at).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        ))
                    ) : (
                        <Alert color="info" className="d-flex align-items-center">
                            <AlertCircle size={24} className="me-2" />
                            <div>
                                Nessuna richiesta trovata per il filtro selezionato.
                            </div>
                        </Alert>
                    )}
                </SimpleBar>
            </>
        );
    };

    return (
        <>
            <Card className="shadow border-0">
                <CardBody>
                    {error ? (
                        <Alert color="danger" className="d-flex align-items-center">
                            <AlertCircle size={24} className="me-2" />
                            <div>
                                {error}
                            </div>
                        </Alert>
                    ) : (
                        renderContent()
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default ClientRequestsClienti;
