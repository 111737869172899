import React, { useState, useEffect, useMemo } from 'react';
import { Button, Row, Col, Input, InputGroup } from 'reactstrap';
import Select from 'react-select';
import { Filter, RotateCcw } from 'lucide-react';

const DomainFilters = ({ domains, setFilteredDomains, tldOptions, isExpiringWithinTwoMonths }) => {
    const [showExpiringOnly, setShowExpiringOnly] = useState(false);
    const [selectedTld, setSelectedTld] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [tldInputValue, setTldInputValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedLockStatus, setSelectedLockStatus] = useState(null);

    useEffect(() => {
        const filtered = domains.filter(domain =>
            domain.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (!showExpiringOnly || isExpiringWithinTwoMonths(domain.expires_at)) &&
            (!selectedTld || domain.name.endsWith(selectedTld.value)) &&
            (!selectedStatus || domain.status_code === selectedStatus.value) &&
            (!selectedLockStatus || domain.lock_status === selectedLockStatus.value)
        );
        setFilteredDomains(filtered);
    }, [domains, searchTerm, showExpiringOnly, selectedTld, selectedStatus, selectedLockStatus, isExpiringWithinTwoMonths, setFilteredDomains]);

    const resetFilters = () => {
        setSearchTerm('');
        setShowExpiringOnly(false);
        setSelectedTld(null);
        setSelectedStatus(null);
        setSelectedLockStatus(null);
        setTldInputValue('');
    };

    const filteredTldOptions = useMemo(() => {
        const inputValueLower = tldInputValue.toLowerCase();
        const exactMatch = tldOptions.find(option => option.value.toLowerCase() === inputValueLower);
        const filtered = tldOptions.filter(option =>
            option.value.toLowerCase().includes(inputValueLower) &&
            option.value.toLowerCase() !== inputValueLower
        );

        if (exactMatch) {
            return [exactMatch, ...filtered];
        } else if (inputValueLower) {
            return [{ value: inputValueLower, label: inputValueLower }, ...filtered];
        }

        return filtered;
    }, [tldOptions, tldInputValue]);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '38px',
            height: '38px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '38px',
            padding: '0 6px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '38px',
        }),
    };

    return (
        <Row className="mb-4 d-flex align-items-center">
            <Col md={3}>
                <InputGroup>
                    <Input
                        type="text"
                        placeholder="Cerca domini..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </InputGroup>
            </Col>
            <Col md={2}>
                <Select
                    options={filteredTldOptions}
                    value={selectedTld}
                    onChange={setSelectedTld}
                    onInputChange={(value) => setTldInputValue(value)}
                    inputValue={tldInputValue}
                    placeholder="TLD..."
                    isClearable
                    isSearchable
                    styles={customStyles}
                />
            </Col>
            <Col md={2}>
                <Select
                    options={[
                        { value: '801', label: 'TransferIn Pending' },
                        { value: '901', label: 'Registration Pending' },
                        { value: '1000', label: 'Registered' },
                        { value: '1001', label: 'Quarantined' }
                    ]}
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    placeholder="Stato..."
                    isClearable
                    isSearchable
                    styles={customStyles}
                />
            </Col>
            <Col md={2}>
                <Select
                    options={[
                        { value: 'locked', label: 'Bloccato' },
                        { value: 'pending lock', label: 'Blocco in sospeso' },
                        { value: 'pending unlock', label: 'Sblocco in sospeso' },
                        { value: 'unlocked', label: 'Sbloccato' }
                    ]}
                    value={selectedLockStatus}
                    onChange={setSelectedLockStatus}
                    placeholder="Stato di Blocco..."
                    isClearable
                    isSearchable
                    styles={customStyles}
                />
            </Col>
            <Col md={3} className="d-flex justify-content-end">
                <Button
                    color={showExpiringOnly ? "danger" : "secondary"}
                    className="me-2"
                    onClick={() => setShowExpiringOnly(!showExpiringOnly)}
                >
                    <Filter size={14} className="me-1" />
                    {showExpiringOnly ? "Tutti i domini" : "Solo in scadenza"}
                </Button>
                <Button color="primary" onClick={resetFilters}>
                    <RotateCcw size={14} className="me-1" />
                    Reset Filtri
                </Button>
            </Col>
        </Row>
    );
};

export default DomainFilters;
