import React from 'react';

const Alert = React.forwardRef(({ children, variant = "default", className, ...props }, ref) => {
    const baseStyle = "p-4 rounded-lg border";
    const variantStyles = {
        default: "bg-gray-100 border-gray-200 text-gray-800",
        destructive: "bg-red-100 border-red-200 text-red-800",
        success: "bg-green-100 border-green-200 text-green-800",
        warning: "bg-yellow-100 border-yellow-200 text-yellow-800",
    };

    return (
        <div
            ref={ref}
            role="alert"
            className={`${baseStyle} ${variantStyles[variant]} ${className}`}
            {...props}
        >
            {children}
        </div>
    );
});

Alert.displayName = "Alert";

const AlertTitle = React.forwardRef(({ className, children, ...props }, ref) => (
    <h5
        ref={ref}
        className={`mb-1 font-medium leading-none tracking-tight ${className}`}
        {...props}
    >
        {children}
    </h5>
));

AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef(({ className, children, ...props }, ref) => (
    <div
        ref={ref}
        className={`text-sm [&_p]:leading-relaxed ${className}`}
        {...props}
    >
        {children}
    </div>
));

AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };
