import React, { useState } from 'react';
import {
    Edit,
    Info,
    RotateCcw,
    Clock,
    ExternalLink,
    Plus,
    Minus
} from 'lucide-react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip,
    Input
} from 'reactstrap';

const DomainActionsMenu = ({ row, handleViewDetails, isExpiringWithinTwoMonths }) => {
    const [modal, setModal] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [renewalYears, setRenewalYears] = useState(1);

    const isRenewalPeriod = () => {
        return isExpiringWithinTwoMonths(row.expires_at);
    };

    const getRenewalTooltipMessage = () => {
        if (!isRenewalPeriod()) {
            return 'Il dominio non è ancora in periodo di rinnovo';
        }
        return 'Rinnova';
    };

    const toggle = () => {
        setModal(!modal);
        if (!modal) {
            setRenewalYears(1);
        }
    };

    const handleActionClick = (action) => {
        if (action === 'details') {
            handleViewDetails(row);
            return;
        }
        setSelectedAction(action);
        setModal(true);
    };

    const handleYearsChange = (value) => {
        const newValue = parseInt(value);
        if (!isNaN(newValue) && newValue >= 1 && newValue <= 10) {
            setRenewalYears(newValue);
        }
    };

    const incrementYears = () => {
        if (renewalYears < 10) {
            setRenewalYears(prev => prev + 1);
        }
    };

    const decrementYears = () => {
        if (renewalYears > 1) {
            setRenewalYears(prev => prev - 1);
        }
    };

    const calculateNewExpiryDate = () => {
        const currentExpiry = new Date(row.expires_at);
        const newExpiry = new Date(currentExpiry);
        newExpiry.setFullYear(newExpiry.getFullYear() + renewalYears);
        return newExpiry.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const renderModalContent = () => {
        if (!selectedAction) return null;

        const modalContents = {
            edit: {
                title: "Modifica Dominio",
                content: (
                    <div className="p-3">
                        <h5 className="mb-4">Modifica dettagli per {row.name}</h5>
                        <p className="text-muted">Funzionalità di modifica in sviluppo...</p>
                    </div>
                )
            },
            renew: {
                title: "Rinnova Dominio",
                content: (
                    <div className="p-3">
                        <h5 className="mb-4">Rinnovo dominio {row.name}</h5>
                        <div className="mb-4">
                            <label className="form-label">Durata del rinnovo</label>
                            <div className="d-flex align-items-center" style={{ maxWidth: '200px' }}>
                                <Button
                                    color="light"
                                    className="p-1"
                                    onClick={decrementYears}
                                    disabled={renewalYears <= 1}
                                    style={{ width: '32px', height: '32px' }}
                                >
                                    <Minus size={16} />
                                </Button>
                                <Input
                                    type="number"
                                    value={renewalYears}
                                    onChange={(e) => handleYearsChange(e.target.value)}
                                    min="1"
                                    max="10"
                                    className="mx-2 text-center"
                                    style={{ width: '60px' }}
                                />
                                <Button
                                    color="light"
                                    className="p-1"
                                    onClick={incrementYears}
                                    disabled={renewalYears >= 10}
                                    style={{ width: '32px', height: '32px' }}
                                >
                                    <Plus size={16} />
                                </Button>
                                <span className="ms-2">anni</span>
                            </div>
                        </div>
                        <div className="alert alert-info">
                            <p className="mb-0">Confermi di voler rinnovare questo dominio per {renewalYears} {renewalYears === 1 ? 'anno' : 'anni'}?</p>
                            <p className="mb-0 mt-2">
                                <small>
                                    Il dominio verrà rinnovato fino al {calculateNewExpiryDate()}
                                </small>
                            </p>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <Button color="secondary" className="me-2" onClick={toggle}>
                                Annulla
                            </Button>
                            <Button color="primary">
                                Conferma Rinnovo
                            </Button>
                        </div>
                    </div>
                )
            },
            renewalMethod: {
                title: "Modifica Metodo di Rinnovo",
                content: (
                    <div className="p-3">
                        <h5 className="mb-4">Modifica metodo di rinnovo per {row.name}</h5>
                        <div className="form-check mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="renewalMethod"
                                id="autoRenew"
                                defaultChecked={row.renewal_mode === 'autoRenew'}
                            />
                            <label className="form-check-label" htmlFor="autoRenew">
                                Rinnovo Automatico
                                <small className="d-block text-muted">Il dominio verrà rinnovato automaticamente alla scadenza</small>
                            </label>
                        </div>
                        <div className="form-check mb-4">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="renewalMethod"
                                id="manualRenew"
                                defaultChecked={row.renewal_mode !== 'autoRenew'}
                            />
                            <label className="form-check-label" htmlFor="manualRenew">
                                Rinnovo Manuale
                                <small className="d-block text-muted">Dovrai rinnovare manualmente il dominio prima della scadenza</small>
                            </label>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button color="secondary" className="me-2" onClick={toggle}>
                                Annulla
                            </Button>
                            <Button color="primary">
                                Salva Modifiche
                            </Button>
                        </div>
                    </div>
                )
            }
        };

        return {
            title: modalContents[selectedAction].title,
            content: modalContents[selectedAction].content
        };
    };

    return (
        <div className="d-flex" style={{ gap: '2px' }}>
            <Button
                id={`editBtn-${row.id}`}
                color="info"
                size="sm"
                className="p-1"
                style={{ width: '28px', height: '28px' }}
                onClick={() => handleActionClick('edit')}
            >
                <Edit size={14} />
            </Button>
            <UncontrolledTooltip target={`editBtn-${row.id}`}>
                Modifica
            </UncontrolledTooltip>

            <Button
                id={`detailsBtn-${row.id}`}
                color="secondary"
                size="sm"
                className="p-1"
                style={{ width: '28px', height: '28px' }}
                onClick={() => handleActionClick('details')}
            >
                <Info size={14} />
            </Button>
            <UncontrolledTooltip target={`detailsBtn-${row.id}`}>
                Dettagli
            </UncontrolledTooltip>

            <Button
                id={`renewBtn-${row.id}`}
                color="success"
                size="sm"
                className="p-1"
                style={{ width: '28px', height: '28px' }}
                onClick={() => handleActionClick('renew')}
                disabled={!isRenewalPeriod()}
            >
                <RotateCcw size={14} />
            </Button>
            <UncontrolledTooltip target={`renewBtn-${row.id}`}>
                {getRenewalTooltipMessage()}
            </UncontrolledTooltip>

            <Button
                id={`changeMethodBtn-${row.id}`}
                color="warning"
                size="sm"
                className="p-1"
                style={{ width: '28px', height: '28px' }}
                onClick={() => handleActionClick('renewalMethod')}
            >
                <Clock size={14} />
            </Button>
            <UncontrolledTooltip target={`changeMethodBtn-${row.id}`}>
                Cambia metodo di rinnovo
            </UncontrolledTooltip>

            <Button
                id={`visitBtn-${row.id}`}
                color="primary"
                size="sm"
                className="p-1"
                style={{ width: '28px', height: '28px' }}
                onClick={() => window.open(`http://${row.name}`, '_blank')}
            >
                <ExternalLink size={14} />
            </Button>
            <UncontrolledTooltip target={`visitBtn-${row.id}`}>
                Visita Sito
            </UncontrolledTooltip>

            {selectedAction && (
                <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>
                        {renderModalContent()?.title}
                    </ModalHeader>
                    <ModalBody>
                        {renderModalContent()?.content}
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

export default DomainActionsMenu;
