import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
  Alert
} from "reactstrap";

import logoDark from "../../assets/images/logo-dark.png";
import { useAuth } from "../../contexts/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loginError, setLoginError] = useState(null);

  useEffect(() => {
    document.title = "Login | Whiterady - Domini admin";
    document.body.className = "bg-pattern";
    return () => {
      document.body.className = "";
    };
  }, []);

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await login(values.email, values.password);
        // console.log('Login '+response.stato);
        if (response.stato ==='ok') {
          localStorage.removeItem('encryptedDomainRegistrationData');
          localStorage.removeItem('selectedDomain');
          navigate('/dashboard');
        } else if (response.stato === 'invalid_credentials') {
            setLoginError("Credenziali non valide");
        }
      } catch (error) {
        console.error('Login error:', error);
        setLoginError('Si è verificato un errore durante il login');
      }
    }
  });

  return (
      <React.Fragment>
        <div className="bg-overlay"></div>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center mb-4">
                      <Link to="/">
                        <img src={logoDark} alt="logo" height="100" className="auth-logo logo-dark mx-auto"/>
                      </Link>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">Benvenuto!</h4>
                    <p className="mb-5 text-center">Accedi al pannello di gestione domini.</p>
                    <Form onSubmit={validation.handleSubmit}>
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email</Label>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                            invalid={!!(validation.touched.email && validation.errors.email)} // Conversione in booleano
                        />
                        {validation.touched.email && validation.errors.email && (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        )}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="password" className="form-label">Password</Label>
                        <Input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Enter password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password}
                            invalid={validation.touched.password && validation.errors.password}
                        />
                        {validation.touched.password && validation.errors.password && (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        )}
                      </div>
                      <Row className="mb-3">
                        <Col>
                          <div className="form-check">
                            <Input type="checkbox" className="form-check-input" id="rememberMe"/>
                            <Label className="form-check-label" htmlFor="rememberMe">Remember me</Label>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-grid">
                        <Button color="primary" type="submit">
                          Log In
                        </Button>
                      </div>
                    </Form>
                    {loginError && (
                        <Alert color="danger" className="mt-3">
                          {loginError}
                        </Alert>
                    )}
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="text-white-50">
                    © {new Date().getFullYear()} Whiterady.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default Login;
