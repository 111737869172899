import {protectedApi} from "../api";
import * as url from "../url_config";


const clientRequestApi = {

    getContactsByType: async (contactType) => {
        try {
            const contactsByTypeData = await protectedApi.post(url.SET_LIST_CONTACTS, {type : contactType});
            return contactsByTypeData.data;
        } catch (error) {
            console.error('Failed to fetch clients:', error);
            throw error;
        }
    },
}

export default clientRequestApi;
