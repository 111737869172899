import React, { useEffect, useState, useCallback } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Spinner,
    Alert
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import itLocale from '@fullcalendar/core/locales/it';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import CalendarioEventi from "../../CommonData/Data/CalendarData";

const DomainCalendar = () => {
    const [events, setEvents] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await CalendarioEventi();
                if (Array.isArray(data) && data.length > 0) {
                    setEvents(data);
                } else {
                    throw new Error("Dati non validi o vuoti");
                }
                setError(null);
            } catch (err) {
                console.error("Error fetching calendar data:", err);
                setError("Errore nel caricamento dei dati del calendario. Contattare l'amministratore.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleEventClick = useCallback((clickInfo) => {
        setSelectedEvent(clickInfo.event);
        setModal(true);
    }, []);

    const closeModal = useCallback(() => {
        setModal(false);
        setSelectedEvent(null);
    }, []);

    const renderEventContent = (eventInfo) => {
        return (
            <div className="fc-event-main-frame">
                <div className="fc-event-time">{eventInfo.timeText}</div>
                <div className="fc-event-title">{eventInfo.event.title}</div>
            </div>
        );
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center" style={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                </div>
            );
        }

        if (error || events.length === 0) {
            return (
                <Alert color="danger" className="text-center" style={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {error || "Nessun evento disponibile nel calendario"}
                </Alert>
            );
        }

        return (
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, bootstrap5Plugin]}
                initialView="dayGridMonth"
                themeSystem="bootstrap"
                locale={itLocale}
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                }}
                buttonText={{
                    today: 'Oggi',
                    month: 'Mese',
                    week: 'Settimana',
                    day: 'Giorno',
                    list: 'Lista'
                }}
                events={events}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                height="auto"
            />
        );
    };

    const formatDate = (date) => {
        return format(new Date(date), "d MMMM yyyy", { locale: it });
    };

    const formatTime = (date) => {
        return format(new Date(date), "HH:mm", { locale: it });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Gestore Domini" breadcrumbItem="Calendario Scadenze"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    {renderContent()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Dettagli Evento</ModalHeader>
                <ModalBody>
                    {selectedEvent && (
                        <>
                            <h4>{selectedEvent.title}</h4>
                            <p>Scadenza Data: {formatDate(selectedEvent.start)}</p>
                            <p>Ora: {formatTime(selectedEvent.start)}</p>
                            {selectedEvent.extendedProps && selectedEvent.extendedProps.description && (
                                <p>Descrizione: {selectedEvent.extendedProps.description}</p>
                            )}
                        </>
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default DomainCalendar;