import CryptoJS from 'crypto-js';

export const getInitialFormData = (domain, tld) => ({
    domain_name: domain || '',
    tld: tld || '',
    subscription_period: '1',
    renewal_mode: 'autorenew',
    folder_id: '',
    nameserver_profile_id: '',
    zone_profile_id: '',
    nameservers: [{ priority: '', fqdn: '', ip_address: '' }],
    contacts: {
        admin: {},
        billing: {},
        tech: {},
        org: {}
    },
    dnssec: [],
    whois_privacy: 'No',
    accept_registration_terms: 'No'
});

export const encryptData = (data, key) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};

export const decryptData = (ciphertext, key) => {
    if (!ciphertext) return null;
    try {
        const bytes = CryptoJS.AES.decrypt(ciphertext, key);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (error) {
        console.error('Errore di decifratura:', error);
        return null;
    }
};

export const isStepComplete = (step, formData) => {
    switch (step) {
        case 1:
            return formData.subscription_period && formData.subscription_period > 0;
        case 2:
            return Object.values(formData.contacts).every(contact =>
                contact.firstname && contact.lastname && contact.email && contact.voice);
        case 3:
            return formData.nameservers.length >= 2 && formData.nameservers.slice(0, 2).every(ns =>
                ns.fqdn && ns.fqdn.trim() !== '');
        case 4:
            return formData.renewal_mode;
        case 5:
            return true;
        default:
            return false;
    }
};

export const isContactComplete = (contactType, contacts) => {
    const contact = contacts[contactType];
    return contact.firstname && contact.lastname && contact.email && contact.voice;
};

export const validateFormData = (formData) => {
    // Validazione profonda dei dati del form
    try {
        // Verifica che i campi principali esistano
        if (!formData.domain_name || !formData.tld) {
            throw new Error('Dati dominio mancanti');
        }

        // Verifica che i contatti siano completi
        const contacts = formData.contacts;
        for (const type of ['admin', 'billing', 'tech', 'org']) {
            if (!contacts[type] || !isContactComplete(type, contacts)) {
                throw new Error(`Dati contatto ${type} incompleti`);
            }
        }

        // Verifica nameservers
        if (!Array.isArray(formData.nameservers) || formData.nameservers.length < 2) {
            throw new Error('Nameserver non validi');
        }

        // Verifica che non ci siano valori undefined o null nei campi principali
        const mainFields = ['subscription_period', 'renewal_mode', 'whois_privacy', 'accept_registration_terms'];
        for (const field of mainFields) {
            if (formData[field] === undefined || formData[field] === null) {
                throw new Error(`Campo ${field} non valido`);
            }
        }

        return true;
    } catch (error) {
        console.error('Validazione form fallita:', error);
        return false;
    }
};

export const cleanFormData = (formData) => {
    // Pulisce i dati del form rimuovendo campi non necessari e normalizzando i valori
    const cleanData = JSON.parse(JSON.stringify(formData));

    // Normalizza i valori booleani
    cleanData.accept_registration_terms = cleanData.accept_registration_terms === 'Yes';
    cleanData.whois_privacy = cleanData.whois_privacy === 'Yes';

    // Rimuove campi vuoti dai contatti
    Object.keys(cleanData.contacts).forEach(type => {
        const contact = cleanData.contacts[type];
        Object.keys(contact).forEach(key => {
            if (!contact[key]) {
                delete contact[key];
            }
        });
    });

    // Filtra nameserver validi
    cleanData.nameservers = cleanData.nameservers.filter(ns => ns.fqdn && ns.fqdn.trim() !== '');

    return cleanData;
};
