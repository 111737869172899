import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Table, Alert } from 'reactstrap';
import { CreditCard, Plus, Pencil, Trash2 } from 'lucide-react';
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import apiCalls from "../../contexts/apiCall";

const PriceListManagement = () => {
    const [priceLists, setPriceLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [editingPriceList, setEditingPriceList] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        description: ""
    });

    const fetchPriceLists = async () => {
        setLoading(true);
        try {
            const response = await apiCalls.settingApi.listPrices();
            setPriceLists(response);
        } catch (err) {
            setError("Errore durante il caricamento dei listini prezzi.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPriceLists();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm("Sei sicuro di voler eliminare questo listino prezzi?")) {
            try {
                await apiCalls.settingApi.deletePriceList(id);
                setSuccess("Listino prezzi eliminato con successo!");
                fetchPriceLists();
            } catch (err) {
                setError("Errore durante l'eliminazione del listino prezzi.");
                console.error("Error:", err);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (editingPriceList) {
                await apiCalls.settingApi.updatePriceList(editingPriceList.id, formData);
                setSuccess("Listino prezzi modificato con successo!");
            } else {
                await apiCalls.settingApi.storePriceList(formData);
                setSuccess("Listino prezzi creato con successo!");
            }
            closeModal();
            fetchPriceLists();
        } catch (err) {
            setError("Errore durante il salvataggio del listino prezzi.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setFormData({
            name: "",
            description: ""
        });
        setEditingPriceList(null);
    };

    const openModal = (priceList = null) => {
        if (priceList) {
            setFormData({
                name: priceList.name,
                description: priceList.description || ""
            });
            setEditingPriceList(priceList);
        } else {
            resetForm();
        }
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        resetForm();
    };

    const columns = [
        {
            name: "Nome Listino",
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: "Descrizione",
            selector: row => row.description,
            sortable: true,
            grow: 3,
            wrap: true
        },
        {
            name: "Data Creazione",
            selector: row => new Date(row.created_at).toLocaleDateString(),
            sortable: true
        },
        {
            name: "Ultima Modifica",
            selector: row => new Date(row.updated_at).toLocaleDateString(),
            sortable: true
        },
        {
            name: "Azioni",
            cell: row => (
                <div>
                    <Button
                        color="info"
                        size="sm"
                        className="me-2"
                        onClick={() => openModal(row)}
                    >
                        <Pencil size={16} />
                    </Button>
                    <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDelete(row.id)}
                    >
                        <Trash2 size={16} />
                    </Button>
                </div>
            ),
            button: true,
            width: "150px"
        }
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: "72px",
            }
        },
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                backgroundColor: "#f8f9fa",
                fontWeight: "bold"
            },
        },
        cells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
            },
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Impostazioni" breadcrumbItem="Listini Prezzi" />

                    {error && (
                        <Alert color="danger" toggle={() => setError("")}>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert color="success" toggle={() => setSuccess("")}>
                            {success}
                        </Alert>
                    )}

                    <Row>
                        <Col lg={12}>
                            <Card className="shadow-sm">
                                <CardBody>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4 className="card-title mb-0">
                                            <CreditCard className="me-2" size={24} />
                                            Listini Prezzi
                                        </h4>
                                        <Button color="primary" onClick={() => openModal()}>
                                            <Plus size={16} className="me-1" />
                                            Nuovo Listino
                                        </Button>
                                    </div>

                                    <DataTable
                                        columns={columns}
                                        data={priceLists}
                                        progressPending={loading}
                                        pagination
                                        customStyles={customStyles}
                                        noDataComponent={
                                            <div className="p-4">
                                                Nessun listino prezzi trovato
                                            </div>
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal isOpen={modalOpen} toggle={closeModal} size="lg" centered>
                        <ModalHeader toggle={closeModal}>
                            {editingPriceList ? "Modifica Listino Prezzi" : "Nuovo Listino Prezzi"}
                        </ModalHeader>
                        <Form onSubmit={handleSubmit}>
                            <ModalBody>
                                <FormGroup className="mb-4">
                                    <Label for="name">Nome Listino *</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="es. Listino Standard"
                                        value={formData.name}
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="description">Descrizione</Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        id="description"
                                        placeholder="Inserisci una descrizione per il listino..."
                                        value={formData.description}
                                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                        rows="4"
                                    />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="light" onClick={closeModal}>
                                    Annulla
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Salvataggio..." : "Salva"}
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PriceListManagement;
