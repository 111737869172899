import { protectedApi, publicApi } from "../api";
import * as url from "../url_config";
import {jwtDecode} from "jwt-decode";

const auth = {
    checkUser: async () => {
        try {
            const userData = await protectedApi.get(url.POST_USER_ME);
            return userData.data;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            throw error;
        }
    },

    login: async (email, password) => {
        try {
            return await publicApi.post(url.POST_USER_LOGIN, { email, password });
        } catch (error) {
            console.error('Login failed:', error);
            return { ok: false, error: 'Login failed' };
        }
    },

    register: async (name, email, password, password_confirmation) => {
        try {
            const response = await publicApi.post(url.POST_USER_REGISTER, {
                name, email, password, password_confirmation
            });
            return { ok: true, data: response.data };
        } catch (error) {
            console.error('Registration failed:', error);
            return { ok: false, error: 'Registration failed' };
        }
    },

    profileClientAss: () => {
        const token = localStorage.getItem('token');
        if (!token) return null;
        const decodedToken = jwtDecode(token);
        return decodedToken.profile_ids || [];
    },
};

export default auth;
