import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Input } from 'reactstrap';
import Select from 'react-select';
import { Filter, RotateCcw } from 'lucide-react';
import apiCalls from "../../../../../contexts/apiCall";

const DomainFilters = ({ onFilterChange, filters }) => {
    const [tldOptions, setTldOptions] = useState([]);

    useEffect(() => {
        fetchTldList();
    }, []);

    const fetchTldList = async () => {
        try {
            const response = await apiCalls.dominiApi.getTldList();
            setTldOptions(response.map(tld => ({ value: tld, label: tld })));
        } catch (error) {
            console.error('Error fetching TLD list:', error);
        }
    };

    const handleInputChange = (key, value) => {
        console.log('Filter changed:', key, value);
        onFilterChange({ [key]: value });
    };

    const resetFilters = () => {
        console.log('Resetting filters');
        onFilterChange({
            search: '',
            tld: null,
            status: null,
            lock_status: null,
            expiring: false
        });
    };

    // Funzione per controllare se ci sono filtri attivi
    const hasActiveFilters = () => {
        return !!(
            filters.search ||
            filters.tld ||
            filters.status ||
            filters.lock_status ||
            filters.expiring
        );
    };

    return (
        <Row className="mb-4 d-flex align-items-center">
            <Col md={3}>
                <Input
                    type="text"
                    placeholder="Cerca domini..."
                    value={filters.search || ''}
                    onChange={(e) => handleInputChange('search', e.target.value)}
                />
            </Col>
            <Col md={2}>
                <Select
                    options={tldOptions}
                    value={filters.tld}
                    onChange={(selected) => handleInputChange('tld', selected)}
                    placeholder="TLD..."
                    isClearable
                />
            </Col>
            <Col md={2}>
                <Select
                    options={[
                        { value: '801', label: 'TransferIn Pending' },
                        { value: '901', label: 'Registration Pending' },
                        { value: '1000', label: 'Registered' },
                        { value: '1001', label: 'Quarantined' }
                    ]}
                    value={filters.status}
                    onChange={(selected) => handleInputChange('status', selected)}
                    placeholder="Stato..."
                    isClearable
                />
            </Col>
            <Col md={2}>
                <Select
                    options={[
                        { value: 'locked', label: 'Bloccato' },
                        { value: 'pending lock', label: 'Blocco in sospeso' },
                        { value: 'pending unlock', label: 'Sblocco in sospeso' },
                        { value: 'unlocked', label: 'Sbloccato' }
                    ]}
                    value={filters.lock_status}
                    onChange={(selected) => handleInputChange('lock_status', selected)}
                    placeholder="Stato di Blocco..."
                    isClearable
                />
            </Col>
            <Col md={3} className="d-flex justify-content-end">
                <Button
                    color={filters.expiring ? "danger" : "secondary"}
                    className="me-2"
                    onClick={() => handleInputChange('expiring', !filters.expiring)}
                >
                    <Filter size={14} className="me-1" />
                    {filters.expiring ? "Tutti i domini" : "Solo in scadenza"}
                </Button>
                <Button
                    color={hasActiveFilters() ? "danger" : "primary"}
                    onClick={resetFilters}
                >
                    <RotateCcw size={14} className="me-1" />
                    Reset Filtri
                </Button>
            </Col>
        </Row>
    );
};

export default DomainFilters;
