import React, { useState, useMemo } from 'react';
import { CardBody, Row, Col, Input, Label } from "reactstrap";
import { Download, ExternalLink, X, Search } from 'lucide-react';
import { exportDomains } from './exportUtils';
import DataTable from 'react-data-table-component';
import {
    FilterContainer,
    FilterGroup,
    StyledBadge,
    StyledButton,
    StyledCard,
    SummaryContainer,
    SummaryItem, SummaryLabel, SummaryValue
} from "./styleView";


const ExpandedClientView = ({ data }) => {
    const [filters, setFilters] = useState({
        domain_name: '',
        type: '',
        contact_ids: ''
    });

    const resetFilters = () => {
        setFilters({
            domain_name: '',
            type: '',
            contact_ids: ''
        });
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };

    const uniqueTypes = useMemo(() => {
        return [...new Set(data.domains.map(domain => domain.type))];
    }, [data.domains]);

    const filteredDomains = useMemo(() => {
        return data.domains.filter(domain => {
            return (
                domain.domain_name.toLowerCase().includes(filters.domain_name.toLowerCase()) &&
                (filters.type === '' || domain.type === filters.type) &&
                (domain.contact_ids || 'N/A').toString().toLowerCase().includes(filters.contact_ids.toLowerCase())
            );
        });
    }, [data.domains, filters]);

    const domainSummary = useMemo(() => {
        const summary = {
            total: filteredDomains.length,
            admin: 0,
            org: 0,
            tech: 0,
            billing: 0
        };

        filteredDomains.forEach(domain => {
            switch (domain.type.toLowerCase()) {
                case 'admin':
                    summary.admin++;
                    break;
                case 'org':
                    summary.org++;
                    break;
                case 'tech':
                    summary.tech++;
                    break;
                case 'billing':
                    summary.billing++;
                    break;
                default:
                    break;
            }
        });

        return summary;
    }, [filteredDomains]);

    const domainColumns = [
        {
            name: 'Nome Dominio',
            selector: row => row.domain_name,
            sortable: true,
            width: '40%',
            cell: row => (
                <div className="d-flex align-items-center">
                    <span className="text-truncate" style={{maxWidth: '80%'}}>{row.domain_name}</span>
                    <a href={`http://${row.domain_name}`} target="_blank" rel="noopener noreferrer" className="text-primary ms-2">
                        <ExternalLink size={14} />
                    </a>
                </div>
            ),
        },
        {
            name: 'Tipo',
            selector: row => row.type,
            sortable: true,
            width: '30%',
            cell: row => <StyledBadge color="info">{row.type}</StyledBadge>,
        },
        {
            name: 'Contact ID',
            selector: row => row.contact_ids,
            sortable: true,
            width: '30%',
            cell: row => <div className="text-truncate">{row.contact_ids || 'N/A'}</div>,
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#f8f9fa',
                minHeight: '50px',
            },
        },
        headCells: {
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
            },
            highlightOnHoverStyle: {
                backgroundColor: '#f1f3fa',
                transitionDuration: '0.15s',
                transitionProperty: 'background-color',
                outlineStyle: 'solid',
                outlineWidth: '1px',
                outlineColor: '#e0e0e0',
            },
        },
        cells: {
            style: {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
    };

    return (
        <StyledCard className="m-3 border-0 shadow-sm">
            <CardBody>
                <h5 className="mb-4 text-primary">Informazioni Aggiuntive</h5>
                <Row className="mb-4">
                    <Col md={4} className="mb-3">
                        <StyledCard body>
                            <strong className="text-muted">Indirizzo</strong>
                            <p className="mb-0 mt-2">{data.street}, {data.city}, {data.postal_code}</p>
                        </StyledCard>
                    </Col>
                    <Col md={4} className="mb-3">
                        <StyledCard body>
                            <strong className="text-muted">Paese</strong>
                            <p className="mb-0 mt-2">{data.country_code}</p>
                        </StyledCard>
                    </Col>
                    <Col md={4} className="mb-3">
                        <StyledCard body>
                            <strong className="text-muted">Fax</strong>
                            <p className="mb-0 mt-2">{data.fax || 'N/A'}</p>
                        </StyledCard>
                    </Col>
                </Row>
                {data.domains && data.domains.length > 0 && (
                    <>
                        <h6 className="text-primary mb-3">Domini Associati</h6>
                        <FilterContainer>
                            <FilterGroup>
                                <Label for="domain_name">Nome Dominio</Label>
                                <div className="input-group">
                                    <span className="input-group-text"><Search size={14} /></span>
                                    <Input
                                        type="text"
                                        name="domain_name"
                                        id="domain_name"
                                        placeholder="Filtra per nome..."
                                        value={filters.domain_name}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </FilterGroup>
                            <FilterGroup>
                                <Label for="type">Tipo</Label>
                                <Input
                                    type="select"
                                    name="type"
                                    id="type"
                                    value={filters.type}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Tutti</option>
                                    {uniqueTypes.map(type => (
                                        <option key={type} value={type}>{type}</option>
                                    ))}
                                </Input>
                            </FilterGroup>
                            <FilterGroup>
                                <Label for="contact_ids">Contact ID</Label>
                                <div className="input-group">
                                    <span className="input-group-text"><Search size={14} /></span>
                                    <Input
                                        type="text"
                                        name="contact_ids"
                                        id="contact_ids"
                                        placeholder="Filtra per Contact ID..."
                                        value={filters.contact_ids}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </FilterGroup>
                            <StyledButton color="secondary" size="sm" onClick={resetFilters} style={{ height: '38px', marginTop: '32px' }}>
                                <X size={14} className="me-1" /> Azzera filtri
                            </StyledButton>
                        </FilterContainer>
                        <div className="mb-3">
                            <StyledButton color="primary" size="sm" className="me-2" onClick={() => exportDomains(filteredDomains, 'csv', data.contact_name)}>
                                <Download size={14} className="me-1" /> CSV
                            </StyledButton>
                            <StyledButton color="success" size="sm" className="me-2" onClick={() => exportDomains(filteredDomains, 'excel', data.contact_name)}>
                                <Download size={14} className="me-1" /> Excel
                            </StyledButton>
                            <StyledButton color="danger" size="sm" onClick={() => exportDomains(filteredDomains, 'pdf', data.contact_name)}>
                                <Download size={14} className="me-1" /> PDF
                            </StyledButton>
                        </div>
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <DataTable
                                columns={domainColumns}
                                data={filteredDomains}
                                customStyles={customStyles}
                                fixedHeader
                                fixedHeaderScrollHeight="400px"
                                highlightOnHover
                                pointerOnHover
                                striped
                                responsive
                            />
                        </div>
                        <SummaryContainer>
                            <SummaryItem>
                                <SummaryLabel>Totale Domini</SummaryLabel>
                                <SummaryValue>{domainSummary.total}</SummaryValue>
                            </SummaryItem>
                            <SummaryItem>
                                <SummaryLabel>Admin</SummaryLabel>
                                <SummaryValue>{domainSummary.admin}</SummaryValue>
                            </SummaryItem>
                            <SummaryItem>
                                <SummaryLabel>Org</SummaryLabel>
                                <SummaryValue>{domainSummary.org}</SummaryValue>
                            </SummaryItem>
                            <SummaryItem>
                                <SummaryLabel>Tech</SummaryLabel>
                                <SummaryValue>{domainSummary.tech}</SummaryValue>
                            </SummaryItem>
                            <SummaryItem>
                                <SummaryLabel>Billing</SummaryLabel>
                                <SummaryValue>{domainSummary.billing}</SummaryValue>
                            </SummaryItem>
                        </SummaryContainer>
                    </>
                )}
            </CardBody>
        </StyledCard>
    );
};

export default ExpandedClientView;
