import React, { createContext, useContext, useState } from 'react';

const DashboardUpdateContext = createContext();

export const DashboardUpdateProvider = ({ children }) => {
    const [lastUpdate, setLastUpdate] = useState(Date.now());

    const triggerUpdate = () => {
        setLastUpdate(Date.now());
    };

    return (
        <DashboardUpdateContext.Provider value={{ lastUpdate, triggerUpdate }}>
            {children}
        </DashboardUpdateContext.Provider>
    );
};

export const useDashboardUpdate = () => {
    const context = useContext(DashboardUpdateContext);
    if (!context) {
        throw new Error('useDashboardUpdate must be used within a DashboardUpdateProvider');
    }
    return context;
};

export default DashboardUpdateContext;
