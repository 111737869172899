import {protectedApi} from "../api";
import * as url from "../url_config";


const settingApi = {

    listNameserverPack: async (domain, tld) => {
        try {

            const listPack = await protectedApi.get(url.SET_NAMESERVER_SETTINGS_LIST, {domain, tld});
            return listPack.data;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            throw error;
        }
    },

    storeNameserverPack: async (data) => {
        try {

            const storePack = await protectedApi.post(url.SET_NAMESERVER_SETTINGS_STORE, data);
            console.log(data)
            return storePack.data;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            throw error;
        }
    },
    updateNameserverPack: async (data) => {
        try {
            const updatePack = await protectedApi.post(url.SET_NAMESERVER_SETTINGS_UPDATE , data);
            return updatePack.data;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            throw error;
        }
    } ,
    deleteNameserverPack: async (id) => {
        try {
            const deletePack = await protectedApi.post(url.SET_NAMESERVER_SETTINGS_DELETE , id);
            return deletePack.data;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            throw error;
        }
    },
    listPrices: async () => {
        const response = await protectedApi.get(url.SETTING_PRICES_LISTINO);
        return response.data;
    },
    storePriceList: async (data) => {
        const response = await protectedApi.post("/api/price-lists", data);
        return response.data;
    },
    updatePriceList: async (id, data) => {
        const response = await protectedApi.post(`/api/price-lists/${id}`, data);
        return response.data;
    },
    deletePriceList: async (id) => {
        const response = await protectedApi.post(`/api/price-lists/delete/${id}`);
        return response.data;
    }
};

export default settingApi;
