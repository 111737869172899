import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/ErrorPage.css'; // Usiamo lo stesso file CSS per coerenza

const Error403 = () => {
    useEffect(() => {
        document.title = "Access Denied | Your App Name";
    }, []);

    return (
        <div className="error-page">
            <div className="error-content">
                <h1 className="error-title">403</h1>
                <div className="error-message">
                    <h2>Access Denied</h2>
                    <p>Sorry, you don't have permission to access this page.</p>
                </div>
                <div className="error-actions">
                    <Link to="/" className="btn btn-primary">
                        Back to Home
                    </Link>
                    <Link to="/contact" className="btn btn-secondary">
                        Contact Support
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Error403;
