import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const sanitizeFileName = (name) => {
    return name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
};

export const exportToCSV = (data, filename, headers) => {
    const csvContent = [
        headers.join(','),
        ...data.map(item => headers.map(header => item[header] || 'N/A').join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const exportToExcel = (data, filename, headers) => {
    const worksheet = XLSX.utils.json_to_sheet(data.map(item => {
        const row = {};
        headers.forEach(header => {
            row[header] = item[header] || 'N/A';
        });
        return row;
    }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
};

export const exportToPDF = (data, filename, headers) => {
    const doc = new jsPDF();

    doc.autoTable({
        head: [headers],
        body: data.map(item => headers.map(header => item[header] || 'N/A')),
    });

    doc.save(filename);
};

export const exportClients = (data, format, clientName = '') => {
    const headers = ['Nome', 'Azienda', 'Email', 'Telefono', 'Stato'];
    const exportData = data.map(customer => ({
        'Nome': customer.contact_name,
        'Azienda': customer.company,
        'Email': customer.email,
        'Telefono': customer.voice,
        'Stato': customer.validated ? 'Validato' : 'Non Validato'
    }));

    const safeClientName = sanitizeFileName(clientName);
    const baseFilename = safeClientName ? `${safeClientName}_clienti` : 'clienti';

    switch (format) {
        case 'csv':
            exportToCSV(exportData, `${baseFilename}.csv`, headers);
            break;
        case 'excel':
            exportToExcel(exportData, `${baseFilename}.xlsx`, headers);
            break;
        case 'pdf':
            exportToPDF(exportData, `${baseFilename}.pdf`, headers);
            break;
        default:
            console.error('Formato non supportato');
    }
};

export const exportDomains = (domains, format, clientName = '') => {
    const headers = ['Nome Dominio', 'Tipo', 'Contact ID'];
    const exportData = domains.map(domain => ({
        'Nome Dominio': domain.domain_name,
        'Tipo': domain.type,
        'Contact ID': domain.contact_ids || 'N/A'
    }));

    const safeClientName = sanitizeFileName(clientName);
    const baseFilename = safeClientName ? `${safeClientName}_domini_associati` : 'domini_associati';

    switch (format) {
        case 'csv':
            exportToCSV(exportData, `${baseFilename}.csv`, headers);
            break;
        case 'excel':
            exportToExcel(exportData, `${baseFilename}.xlsx`, headers);
            break;
        case 'pdf':
            exportToPDF(exportData, `${baseFilename}.pdf`, headers);
            break;
        default:
            console.error('Formato non supportato');
    }
};