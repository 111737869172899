import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Check } from 'lucide-react';

const SuccessModal = ({
                          isOpen,
                          domainName,
                          tld,
                          onNewRegistration,
                          onBackToDashboard
                      }) => {
    return (
        <Modal
            isOpen={isOpen}
            centered
            backdrop="static"
            keyboard={false}
        >
            <ModalHeader className="bg-success text-white">
                <Check size={20} className="me-2" />
                Richiesta Inviata con Successo
            </ModalHeader>
            <ModalBody>
                <div className="text-center mb-4">
                    <Check size={50} className="text-success mb-3" />
                    <h5>La richiesta di registrazione per il dominio {domainName}.{tld} è stata inoltrata con successo.</h5>
                    <p>La procedura di registrazione è stata avviata e verrà elaborata dal nostro sistema.
                        Riceverai una notifica via email non appena il processo sarà completato.</p>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <Button
                    color="primary"
                    outline
                    onClick={onNewRegistration}
                >
                    Richiedi un altro dominio
                </Button>
                <Button
                    color="primary"
                    onClick={onBackToDashboard}
                >
                    Torna alla Dashboard
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SuccessModal;
