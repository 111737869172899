import {protectedApi} from "../api";
import * as url from "../url_config";

const clientRequestApi = {

    createClientRequest: async (params = {}) => {
        try {
            const clientRequestData = await protectedApi.post(url.SET_RICHIESTE_CLIENTI_CREATE, {params});
            return clientRequestData.data;
        } catch (error) {
            console.error('Failed to fetch clients:', error);
            throw error;
        }
    },
    listRequestClienti: async (params = {}) => {
        try {
            const requestListData = await protectedApi.get(url.SET_LIST_RICHIESTE_CLIENTI);
            // console.log(requestListData);
            return requestListData.data;
        } catch (error) {
            console.error('Failed to fetch clients:', error);
            throw error;
        }
    },

    // Lista delle richieste lato clienti
    listRequestRoleClienti: async (params = {}) => {
        try {

            const id = JSON.parse(localStorage.getItem('authUser')).id;
            const requestListData = await protectedApi.post(url.SET_LIST_RICHIESTE_ROLE_CLIENTE, {id});
            console.log(requestListData);
            return requestListData.data;
        } catch (error) {
            console.error('Failed to fetch clients:', error);
            throw error;
        }
    }

};

export default clientRequestApi;
