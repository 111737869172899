import {protectedApi} from "../api";
import * as url from "../url_config";
import apiCalls from "../apiCall";

const dashboardDataApi = {
    overviewDomini: async (params = {}) => {
        try {
            const dominioListData = await protectedApi.get(url.SET_OVERVIEW_DOMAINS);
            return dominioListData.data;
        } catch (error) {
            console.error('Failed to fetch domains:', error);
            throw error;
        }
    },
    notificheRinnovi: async (params = {}) => {
        try {
            const notificheRinnoviData = await protectedApi.get(url.SET_NOTIFICHE_RINNOVI);

            return notificheRinnoviData.data;
        } catch (error) {
            console.error('Failed to fetch notifiche rinnovi:', error);
            throw error;
        }
    },
    notificheRinnoviNoRenewal: async (params = {}) => {
        try {
            const notificheRinnoviDataNoRenewal = await protectedApi.get(url.SET_NOTIFICHE_RINNOVI_NO_RENEWAL);
            return notificheRinnoviDataNoRenewal.data;
        } catch (error) {
            console.error('Failed to fetch notifiche rinnovi:', error);
            throw error;
        }
    },
    graficoAndamentoDomini: async (viewMode) => {
        try {
            const response = await protectedApi.get(url.SET_ANDAMENTO_DOMAINS, {params: {viewMode}});
            return response.data;
        } catch (error) {
            console.error('Failed to fetch andamento domini:', error);
            throw error;
        }
    },

    // Dasboard Clienti
    notificheRinnoviClienti: async (params = {}) => {
        try {
            const client_ids = apiCalls.auth.profileClientAss();
            const notificheRinnoviData = await protectedApi.post(url.SET_NOTIFICHE_RINNOVI_CLIENTI, {client_ids});
            return notificheRinnoviData.data;
        } catch (error) {
            console.error('Failed to fetch notifiche rinnovi:', error);
            throw error;
        }
    },
    notificheRinnoviNoRenewalClienti: async (params = {}) => {
        try {
            const client_ids = apiCalls.auth.profileClientAss();
            const notificheRinnoviDataNoRenewal = await protectedApi.post(url.SET_NOTIFICHE_RINNOVI_NO_RENEWAL_CLIENTI, {client_ids});
            return notificheRinnoviDataNoRenewal.data;
        } catch (error) {
            console.error('Failed to fetch notifiche rinnovi:', error);
            throw error;
        }
    },
    overviewDominiClienti: async (params = {}) => {
        try {
            const client_ids = apiCalls.auth.profileClientAss();
            const dominioListDataClienti = await protectedApi.post(url.SET_OVERVIEW_DOMAINS_CLIENTI, {client_ids});
            return dominioListDataClienti.data;
        } catch (error) {
            console.error('Failed to fetch domains:', error);
            throw error;
        }
    },

    caricaListaRequestsApi: async (params = {}) => {
        try {
            const requestsApiData = await protectedApi.post(url.SET_REQUESTS_API);
            return requestsApiData.data.original;
        } catch (error) {
            console.error('Failed to fetch requests API data:', error);
            throw error;
        }
    }

};

export default dashboardDataApi;
