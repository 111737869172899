import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../Layout/VerticalLayout';
import {jwtDecode} from 'jwt-decode';

const AuthProtected = ({ roleComponents }) => {
  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token; // Verifica se il token esiste
  };

  const getUserRole = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;

    try {
      const decodedToken = jwtDecode(token); // Decodifica il token JWT
      return decodedToken.role; // Ottieni il ruolo dal token
    } catch (error) {
      console.error('Errore nel decodificare il token:', error);
      return null;
    }
  };

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  const userRole = getUserRole();

  if (!roleComponents[userRole]) {
    return <Navigate to="/403" replace />;
  }

  const Component = roleComponents[userRole];
  return (
      <Layout>
        <Component />
      </Layout>
  );
};

AuthProtected.propTypes = {
  roleComponents: PropTypes.object.isRequired,
};

export { AuthProtected };
