import React from 'react';
import { Row, Col, Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { Globe, RefreshCw, Folder, Shield, User, Mail, Phone, MapPin, Building } from 'lucide-react';
import styled from 'styled-components';

const SectionTitle = styled.h6`
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1rem;
    border-bottom: 1px solid #3498db;
    padding-bottom: 0.3rem;
`;

const InfoCard = styled(Card)`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: none;
    margin-bottom: 1rem;
`;

const ContactCard = styled(Card)`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: none;
    transition: transform 0.2s;
    &:hover {
        transform: translateY(-3px);
    }
`;

const IconWrapper = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: #3498db;
    color: white;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
`;

const CompactListGroupItem = styled(ListGroupItem)`
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
`;

const CompactCardBody = styled(CardBody)`
    padding: 1rem;
`;

const ContactInfo = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const getContactCardBackground = (index) => {
    const colors = ['#f8f9fa', '#e9ecef', '#dee2e6', '#ced4da'];
    return colors[index % colors.length];
};

const formatAddress = (contact) => {
    const parts = [contact.street, contact.city, contact.postal_code, contact.country_code];
    return parts.filter(part => part).join(', ') || 'N/A';
};

// Stile per rendere i dati del dominio più visibili
const HighlightedText = styled.span`
    font-size: 1.2rem;
    font-weight: bold;
    color: #2c3e50;
`;

const RegistrationSummary = ({ formData }) => {
    const contacts = [
        { type: 'admin', ...formData.contacts.admin },
        { type: 'billing', ...formData.contacts.billing },
        { type: 'tech', ...formData.contacts.tech },
        { type: 'org', ...formData.contacts.org }
    ];

    return (
        <div>
            <SectionTitle>Riepilogo Registrazione Dominio</SectionTitle>

            <InfoCard>
                <CompactCardBody>
                    <Row>
                        <Col md={6}>
                            <ListGroup flush>
                                <CompactListGroupItem>
                                    <IconWrapper>
                                        <Globe size={12} />
                                    </IconWrapper>
                                    <strong>Dominio:</strong>
                                    <HighlightedText style={{ marginLeft: '8px' }}>
                                        {formData.domain_name}.{formData.tld}
                                    </HighlightedText>
                                </CompactListGroupItem>
                                <CompactListGroupItem>
                                    <IconWrapper>
                                        <RefreshCw size={12} />
                                    </IconWrapper>
                                    <strong>Periodo:</strong>
                                    <HighlightedText style={{ marginLeft: '8px' }}>
                                        {formData.subscription_period} anni
                                    </HighlightedText>
                                </CompactListGroupItem>
                            </ListGroup>
                        </Col>

                        <Col md={6}>
                            <ListGroup flush>
                                <CompactListGroupItem>
                                    <IconWrapper>
                                        <RefreshCw size={12} />
                                    </IconWrapper>
                                    <strong>Rinnovo:</strong>
                                    <HighlightedText style={{ marginLeft: '8px' }}>
                                        {formData.renewal_mode}
                                    </HighlightedText>
                                </CompactListGroupItem>
                                <CompactListGroupItem>
                                    <IconWrapper>
                                        <Folder size={12} />
                                    </IconWrapper>
                                    <strong>ID Cartella:</strong>
                                    <span style={{ marginLeft: '8px' }}>
                        {formData.folder_id || 'N/A'}
                    </span>
                                </CompactListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row>
                </CompactCardBody>

            </InfoCard>

            <SectionTitle>Informazioni di Contatto</SectionTitle>
            <Row className="g-2 mb-3">
                {contacts.map((contact, index) => (
                    <Col key={index} xs={12} md={3}>
                        <ContactCard style={{ backgroundColor: getContactCardBackground(index) }}>
                            <CompactCardBody>
                                <h6 className="text-primary mb-2" style={{fontSize: '0.9rem'}}>{contact.type.toUpperCase()}</h6>
                                <ListGroup flush>
                                    <CompactListGroupItem>
                                        <IconWrapper><User size={12} /></IconWrapper>
                                        <ContactInfo>{`${contact.firstname} ${contact.lastname}` || 'N/A'}</ContactInfo>
                                    </CompactListGroupItem>
                                    <CompactListGroupItem>
                                        <IconWrapper><Mail size={12} /></IconWrapper>
                                        <ContactInfo>{contact.email || 'N/A'}</ContactInfo>
                                    </CompactListGroupItem>
                                    <CompactListGroupItem>
                                        <IconWrapper><Phone size={12} /></IconWrapper>
                                        <ContactInfo>{contact.voice || 'N/A'}</ContactInfo>
                                    </CompactListGroupItem>
                                    <CompactListGroupItem>
                                        <IconWrapper><MapPin size={12} /></IconWrapper>
                                        <ContactInfo>{formatAddress(contact)}</ContactInfo>
                                    </CompactListGroupItem>
                                    <CompactListGroupItem>
                                        <IconWrapper><Building size={12} /></IconWrapper>
                                        <ContactInfo>{contact.company || 'N/A'}</ContactInfo>
                                    </CompactListGroupItem>
                                </ListGroup>
                            </CompactCardBody>
                        </ContactCard>
                    </Col>
                ))}
            </Row>

            {/* Nameserver and Impostazioni Avanzate on the same row */}
            <Row>
                <Col md={6}>
                    <InfoCard>
                        <CompactCardBody>
                            <SectionTitle>Configurazione Nameserver</SectionTitle>
                            <ListGroup flush>
                                {formData.nameservers.map((ns, index) => (
                                    <CompactListGroupItem key={index}>
                                        <strong>NS{index + 1}:</strong> {ns.fqdn} (Priorità: {ns.priority}, IP: {ns.ip_address})
                                    </CompactListGroupItem>
                                ))}
                            </ListGroup>
                        </CompactCardBody>
                    </InfoCard>
                </Col>
                <Col md={6}>
                    <InfoCard>
                        <CompactCardBody>
                            <SectionTitle>Impostazioni Avanzate</SectionTitle>
                            <ListGroup flush>
                                <CompactListGroupItem>
                                    <IconWrapper><Shield size={12} /></IconWrapper>
                                    <strong>Privacy WHOIS:</strong> {formData.whois_privacy === 'Yes' ? 'Attivata' : 'Disattivata'}
                                </CompactListGroupItem>
                                {formData.dnssec.length > 0 && (
                                    <CompactListGroupItem>
                                        <IconWrapper><Shield size={12} /></IconWrapper>
                                        <strong>DNSSEC:</strong> Configurato
                                    </CompactListGroupItem>
                                )}
                            </ListGroup>
                        </CompactCardBody>
                    </InfoCard>
                </Col>
            </Row>
        </div>
    );
};

export default RegistrationSummary;
