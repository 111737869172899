import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardHeader, Button, Badge, Table, Spinner } from 'reactstrap';
import apiCalls from "../../../../contexts/apiCall";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";


const DomainDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [domainData, setDomainData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDomainDetails = async () => {
            try {
                setLoading(true);
                const response = await apiCalls.dominiActionsApi.getDomainDetails(id);
                setDomainData(response);
                setError(null);
            } catch (err) {
                console.error('Error fetching domain details:', err);
                setError('Si è verificato un errore nel recupero dei dettagli del dominio.');
            } finally {
                setLoading(false);
            }
        };

        fetchDomainDetails();
    }, [id]);

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    if (loading) {
        return (
            <div className="page-content d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
            </div>
        );
    }

    if (error || !domainData) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Domini" breadcrumbItem="Dettagli Dominio" />
                    <Card className="bg-danger text-white">
                        <CardBody>
                            <h4 className="mb-3">{error || 'Errore: Dati del dominio non disponibili'}</h4>
                            <Button color="light" onClick={() => navigate('/manage-domini')}>
                                Torna alla lista dei domini
                            </Button>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Domini" breadcrumbItem="Dettagli Dominio" />
                <Card>
                    <CardHeader className="bg-primary text-white">
                        <h4 className="mb-0">{domainData.name}</h4>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-4">
                            <Col md={6} className="mb-3 mb-md-0">
                                <Card className="h-100 border">
                                    <CardBody>
                                        <h5 className="card-title mb-3">Informazioni Principali</h5>
                                        <p><strong>Stato:</strong> <Badge color={domainData.status_code === '1000' ? 'success' : 'warning'}>{domainData.status_description}</Badge></p>
                                        <p><strong>Stato di Blocco:</strong> <Badge color={domainData.lock_status === 'locked' ? 'danger' : 'success'}>{domainData.lock_status}</Badge></p>
                                        <p><strong>Data di Scadenza:</strong> {formatDate(domainData.expires_at)}</p>
                                        <p><strong>Rinnovo Automatico:</strong> {domainData.renewal_mode === 'autoRenew' ? 'Sì' : 'No'}</p>
                                        <p><strong>Privacy del Dominio:</strong> {domainData.domain_privacy ? 'Attiva' : 'Non attiva'}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="h-100 border">
                                    <CardBody>
                                        <h5 className="card-title mb-3">Dettagli Tecnici</h5>
                                        <p><strong>ROID:</strong> {domainData.roid || 'N/A'}</p>
                                        <p><strong>Auth Code:</strong> {domainData.auth_code || 'N/A'}</p>
                                        <p><strong>DNSSEC:</strong> {domainData.dnssec || 'Non configurato'}</p>
                                        <p><strong>Pending Status:</strong> {domainData.pending_status}</p>
                                        <p><strong>Pending Action:</strong> {domainData.pending_action || 'N/A'}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {domainData.contacts && domainData.contacts.length > 0 && (
                            <>
                                <h5 className="mt-4 mb-3">Contatti</h5>
                                <Row>
                                    {domainData.contacts.map((contact, index) => (
                                        <Col md={6} key={index} className="mb-3">
                                            <Card className="border">
                                                <CardHeader className="bg-light">
                                                    <h6 className="mb-0">{contact.type.toUpperCase()} Contact</h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <p><strong>Nome:</strong> {contact.name || 'N/A'}</p>
                                                    <p><strong>Azienda:</strong> {contact.company || 'N/A'}</p>
                                                    <p><strong>Email:</strong> {contact.email || 'N/A'}</p>
                                                    <p><strong>Telefono:</strong> {contact.voice || 'N/A'}</p>
                                                    <p><strong>Fax:</strong> {contact.fax || 'N/A'}</p>
                                                    <p><strong>Indirizzo:</strong> {`${contact.street || ''}, ${contact.city || ''}, ${contact.postal_code || ''}, ${contact.country_code || ''}`}</p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </>
                        )}

                        {domainData.nameservers && (
                            <>
                                <h5 className="mt-4 mb-3">Nameservers</h5>
                                <Card className="border">
                                    <CardBody>
                                        <Row>
                                            {JSON.parse(domainData.nameservers).map((ns, index) => (
                                                <Col md={6} key={index} className="mb-2">
                                                    <p className="mb-1"><strong>NS{index + 1}:</strong> {ns.fqdn}</p>
                                                    <p className="text-muted small">IP: {ns.ip}</p>
                                                </Col>
                                            ))}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </>
                        )}

                        <h5 className="mt-4 mb-3">Informazioni Aggiuntive</h5>
                        <Card className="border">
                            <CardBody>
                                <Table responsive className="table-nowrap mb-0">
                                    <tbody>
                                    <tr><td><strong>ID:</strong></td><td>{domainData.id}</td></tr>
                                    <tr><td><strong>Folder ID:</strong></td><td>{domainData.folder_id || 'N/A'}</td></tr>
                                    <tr><td><strong>Contatto Validato:</strong></td><td>{domainData.contact_validated ? 'Sì' : 'No'}</td></tr>
                                    <tr><td><strong>Processato:</strong></td><td>{domainData.processed ? 'Sì' : 'No'}</td></tr>
                                    <tr><td><strong>Ultima Verifica:</strong></td><td>{formatDate(domainData.last_checked_at)}</td></tr>
                                    <tr><td><strong>Errore di Elaborazione:</strong></td><td>{domainData.processing_error || 'Nessuno'}</td></tr>
                                    <tr><td><strong>Aggiornamento Riuscito:</strong></td><td>{domainData.update_successful ? 'Sì' : 'No'}</td></tr>
                                    <tr><td><strong>Data di Creazione:</strong></td><td>{formatDate(domainData.created_at)}</td></tr>
                                    <tr><td><strong>Ultimo Aggiornamento:</strong></td><td>{formatDate(domainData.updated_at)}</td></tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <div className="mt-4 text-end">
                            <Button color="primary" onClick={() => navigate('/manage-domini')}>
                                Torna alla lista dei domini
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default DomainDetails;
