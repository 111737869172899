import React from 'react';
import { Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { MoreVertical, Edit, Trash, Mail } from 'lucide-react';

export const columns = [
    {
        name: 'Nome',
        selector: row => row.contact_name,
        sortable: true,
        width: '20%',
        cell: row => <div className="text-truncate" style={{maxWidth: '100%'}}>{row.contact_name}</div>
    },
    {
        name: 'Azienda',
        selector: row => row.company,
        sortable: true,
        width: '20%',
        cell: row => <div className="text-truncate" style={{maxWidth: '100%'}}>{row.company}</div>
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        width: '25%',
        cell: row => <div className="text-truncate" style={{maxWidth: '100%'}}>{row.email}</div>
    },
    {
        name: 'Telefono',
        selector: row => row.voice,
        sortable: true,
        width: '15%',
        cell: row => <div className="text-truncate" style={{maxWidth: '100%'}}>{row.voice}</div>
    },
    {
        name: 'Stato',
        selector: row => row.validated ? 'Validato' : 'Non Validato',
        sortable: true,
        width: '10%',
        cell: row => (
            <Badge color={row.validated ? 'success' : 'warning'} pill className="px-2 py-1">
                {row.validated ? 'Validato' : 'Non Validato'}
            </Badge>
        ),
    },
    {
        name: 'Azioni',
        width: '10%',
        cell: row => (
            <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-soft-primary btn-sm">
                    <MoreVertical size={16} />
                </DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem href="#"><Edit size={14} className="me-2" /> Modifica</DropdownItem>
                    <DropdownItem href="#"><Mail size={14} className="me-2" /> Invia Email</DropdownItem>
                    <DropdownItem href="#" className="text-danger"><Trash size={14} className="me-2" /> Elimina</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        ),
    },
];

export const customStyles = {
    headCells: {
        style: {
            fontSize: '14px',
            fontWeight: 'bold',
            backgroundColor: '#f8f9fa',
            paddingTop: '12px',
            paddingBottom: '12px',
        },
    },
    rows: {
        style: {
            fontSize: '14px',
        },
        highlightOnHoverStyle: {
            backgroundColor: '#f1f3fa',
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            outlineStyle: 'solid',
            outlineWidth: '1px',
            outlineColor: '#e0e0e0',
        },
    },
};