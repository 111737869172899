import React from "react";
import {Container, Row, Col} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DomainOverview from "./components/DomainOverview";
import RenewalNotifications from "./components/RenewalNotifications";
import LineColumnArea from "./components/LineColumnArea";
import EmailStatusDashboard from "./components/Emailqueue";
import RenewalNotificationsNoAutoRenewal from "./components/RenewalNotificationsNoAutoRenewal";
import ClientRequests from "./components/ClientRequests";
import QuickAccess from "./components/QuickAccess";
import ApiRequestsTable from "./components/ApiRequestsTable";
import {DashboardUpdateProvider} from "./contexts/DashboardUpdateContext";


const Dashboard = () => {
    document.title = "Dashboard | Whiteready - Pannello di gestione domini";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <DashboardUpdateProvider>
                        <Breadcrumbs title="Whiteready" breadcrumbItem="Dashboard"/>
                        <Row>
                            <QuickAccess />
                        </Row>
                        <Row>
                            <Col xl={7}>
                                <ApiRequestsTable/>
                            </Col>
                            <Col xl={5}>
                                <ClientRequests/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <RenewalNotificationsNoAutoRenewal/>
                            </Col>
                            <Col xl={6}>
                                <RenewalNotifications/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <DomainOverview/>
                            </Col>
                            <Col xl={8}>
                                <EmailStatusDashboard/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <LineColumnArea/>
                            </Col>
                        </Row>
                    </DashboardUpdateProvider>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
