import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { layoutTypes } from "../constants/layout";
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/index";
import { authProtectedRoutes, publicRoutes } from "./routes";
import { AuthProtected } from "./AuthProtected";

const getLayout = (layoutType) => {
    switch (layoutType) {
        case layoutTypes.VERTICAL:
            return VerticalLayout;
        default:
            return VerticalLayout;
    }
};

const Index = () => {
    const { layoutType } = useSelector((state) => ({
        layoutType: state.Layout.layoutType,
    }));

    const Layout = getLayout(layoutType);

    return (
        <Routes>
            {/* Rotte pubbliche */}
            {publicRoutes.map((route, idx) => (
                <Route
                    key={idx}
                    path={route.path}
                    element={
                        <NonAuthLayout>
                            {route.component}
                        </NonAuthLayout>
                    }
                />
            ))}

            {/* Rotte protette */}
            {authProtectedRoutes.map((route, idx) => (
                <Route
                    key={idx}
                    path={route.path}
                    element={
                        <AuthProtected roleComponents={route.component.props.roleComponents}>
                            <Layout />
                        </AuthProtected>
                    }
                />
            ))}

            {/* Fallback per rotte non trovate */}
            <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
    );
};

export default Index;


