import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button, Progress } from 'reactstrap';
import { Globe, ChevronRight, ArrowLeft, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { AnimatePresence } from 'framer-motion';
import apiCalls from "../../../../contexts/apiCall";
import { transformDomainData } from "./convertDataJson";

// Importazione dei componenti e delle utilities
import { StyledCard, StepIndicator, Step, StepNumber } from './StyledComponents';
import RegistrationSteps from './RegistrationSteps';
import SuccessModal from './SuccessModal';
import {
    getInitialFormData,
    encryptData,
    decryptData,
    isStepComplete,
    validateFormData,
    cleanFormData
} from './registrationUtils';

const DomainRegistrationForm = ({ domain, tld, onBack }) => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(1);
    const [activeTab, setActiveTab] = useState('admin');
    const [formData, setFormData] = useState(getInitialFormData(domain, tld));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isResetting, setIsResetting] = useState(false);

    const ENCRYPTION_KEY = process.env.REACT_APP_CIFRATE_KEY;

    // Funzione per salvare i dati nel localStorage
    const saveToLocalStorage = useCallback((data) => {
        if (!isResetting) {
            const encryptedData = encryptData(data, ENCRYPTION_KEY);
            localStorage.setItem('encryptedDomainRegistrationData', encryptedData);
        }
    }, [ENCRYPTION_KEY, isResetting]);

    // Effetto per caricare i dati salvati
    useEffect(() => {
        if (!isResetting) {
            const savedData = localStorage.getItem('encryptedDomainRegistrationData');
            if (savedData) {
                try {
                    const decryptedData = decryptData(savedData, ENCRYPTION_KEY);
                    if (decryptedData && validateFormData(decryptedData)) {
                        setFormData(decryptedData);
                        setActiveStep(decryptedData.activeStep || 1);
                        setActiveTab(decryptedData.activeTab || 'admin');
                    }
                } catch (error) {
                    console.error('Errore nel caricamento dei dati salvati:', error);
                    localStorage.removeItem('encryptedDomainRegistrationData');
                }
            }
        }
    }, [ENCRYPTION_KEY, isResetting]);

    // Salva i cambiamenti nel localStorage
    useEffect(() => {
        if (formData && !isResetting) {
            saveToLocalStorage({ ...formData, activeStep, activeTab });
        }
    }, [formData, activeStep, activeTab, saveToLocalStorage, isResetting]);

    // Reset completo del form
    const resetForm = async () => {
        setIsResetting(true);
        try {
            setFormData(getInitialFormData('', ''));
            setActiveStep(1);
            setActiveTab('admin');
            setError('');
            localStorage.removeItem('encryptedDomainRegistrationData');
            localStorage.removeItem('selectedDomain');

            // Attendiamo il completamento del reset
            await new Promise(resolve => setTimeout(resolve, 100));
        } finally {
            setIsResetting(false);
        }
    };

    // Gestione della navigazione post-registrazione
    const handleNewRegistration = async () => {
        setShowSuccessModal(false);
        await resetForm();
        onBack();
    };

    const handleBackToDashboard = async () => {
        setShowSuccessModal(false);
        await resetForm();
        navigate('/dashboard');
    };

    // Gestione dei cambiamenti nel form
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleContactChange = (contactType, contactData) => {
        setFormData(prevState => ({
            ...prevState,
            contacts: {
                ...prevState.contacts,
                [contactType]: contactData
            }
        }));
    };

    const handleNameserverChange = (nameservers) => {
        setFormData(prevState => ({ ...prevState, nameservers }));
    };

    const handleDNSSECChange = (dnssecData) => {
        setFormData(prevState => ({ ...prevState, dnssec: dnssecData }));
    };

    // Navigazione tra i passi
    const handleNextStep = () => {
        if (isStepComplete(activeStep, formData) && activeStep < 5) {
            setActiveStep(prevStep => prevStep + 1);
        }
    };

    const handlePrevStep = () => {
        if (activeStep > 1) {
            setActiveStep(prevStep => prevStep - 1);
        }
    };

    // Gestione dell'invio del form
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (activeStep === 5 && isStepComplete(5, formData)) {
            setLoading(true);
            setError('');
            try {
                // Pulizia e validazione dei dati
                const cleanedData = cleanFormData(formData);
                if (!validateFormData(cleanedData)) {
                    throw new Error('Dati del form non validi');
                }

                // Trasformazione e cifratura dei dati
                const datiTrasformati = transformDomainData(cleanedData);
                // const encryptedData = encryptForLaravel(datiTrasformati);

                // Invio al backend
                const response = await apiCalls.dominiApi.decryptDomainData(datiTrasformati);

                if (response.status === 'success') {
                    setShowSuccessModal(true);
                    localStorage.removeItem('encryptedDomainRegistrationData');
                    localStorage.removeItem('selectedDomain');
                } else {
                    throw new Error(response.message || 'Errore durante la registrazione del dominio');
                }
            } catch (err) {
                console.error('Errore nella registrazione del dominio:', err);
                setError('Si è verificato un errore durante la registrazione del dominio. Riprova più tardi.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Domini" breadcrumbItem="Registrazione Dominio" />
                    <Row>
                        <Col lg={12}>
                            <StyledCard>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4 className="card-title">
                                            <Globe className="me-2" size={24} />
                                            Registrazione Nuovo Dominio
                                        </h4>
                                        <Button color="primary" onClick={onBack}>
                                            <ArrowLeft size={16} className="me-2" />
                                            Torna alla scelta del dominio
                                        </Button>
                                    </div>

                                    <StepIndicator>
                                        {[1, 2, 3, 4, 5].map(step => (
                                            <Step key={step} active={activeStep >= step}>
                                                <StepNumber active={activeStep >= step}>{step}</StepNumber>
                                                <span>
                                                    {step === 1 ? 'Dominio' :
                                                        step === 2 ? 'Contatti' :
                                                            step === 3 ? 'Nameserver' :
                                                                step === 4 ? 'Avanzate' : 'Riepilogo'}
                                                </span>
                                            </Step>
                                        ))}
                                    </StepIndicator>

                                    <Progress value={(activeStep / 5) * 100} className="mb-4" />

                                    <Form onSubmit={handleSubmit}>
                                        <AnimatePresence mode="wait">
                                            <RegistrationSteps
                                                activeStep={activeStep}
                                                activeTab={activeTab}
                                                formData={formData}
                                                setActiveTab={setActiveTab}
                                                handleInputChange={handleInputChange}
                                                handleContactChange={handleContactChange}
                                                handleNameserverChange={handleNameserverChange}
                                                handleDNSSECChange={handleDNSSECChange}
                                                setFormData={setFormData}
                                            />
                                        </AnimatePresence>

                                        {error && (
                                            <div className="alert alert-danger mt-4" role="alert">
                                                {error}
                                            </div>
                                        )}

                                        <div className="d-flex justify-content-between mt-4">
                                            {activeStep > 1 && (
                                                <Button color="primary" onClick={handlePrevStep}>
                                                    <ChevronLeft size={16} className="me-2" />
                                                    Indietro
                                                </Button>
                                            )}
                                            {activeStep < 5 ? (
                                                <Button
                                                    color="primary"
                                                    onClick={handleNextStep}
                                                    disabled={!isStepComplete(activeStep, formData)}
                                                >
                                                    Avanti <ChevronRight size={16} className="ms-2" />
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    disabled={loading || !isStepComplete(5, formData)}
                                                >
                                                    {loading ? 'Registrazione in corso...' : 'Completa Registrazione'}
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                </div>
                            </StyledCard>
                        </Col>
                    </Row>

                    <SuccessModal
                        isOpen={showSuccessModal}
                        domainName={formData.domain_name}
                        tld={formData.tld}
                        onNewRegistration={handleNewRegistration}
                        onBackToDashboard={handleBackToDashboard}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DomainRegistrationForm;
