import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { Alert, Card, CardBody, CardTitle, Spinner, ButtonGroup, Button, Badge, Table } from "reactstrap";
import {
    FileText,
    AlertCircle,
    User,
    Calendar,
    AlertOctagon,
    PlusCircle,
    RefreshCw,
    ToggleLeft,
    Settings,
} from 'lucide-react';
import apiCalls from "../../../../contexts/apiCall";
import moment from 'moment';
import { useDashboardUpdate } from "../contexts/DashboardUpdateContext";

const ApiRequestsTable = () => {
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('pending');
    const [searchTerm, setSearchTerm] = useState('');
    const { lastUpdate } = useDashboardUpdate();

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await apiCalls.dashboardDataApi.caricaListaRequestsApi();
            if (Array.isArray(response.data)) {
                setRequests(response.data);
                filterRequests('pending', response.data, searchTerm);
            } else {
                setRequests([]);
                setFilteredRequests([]);
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching API requests:", err);
            setError("Errore nel caricamento delle richieste");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [lastUpdate]);

    useEffect(() => {
        filterRequests(filter, requests, searchTerm);
    }, [filter, requests, searchTerm]);

    const filterRequests = (selectedFilter, requestsToFilter, search) => {
        let filtered = requestsToFilter;

        if (selectedFilter !== 'all') {
            filtered = filtered.filter(item => item.status === selectedFilter);
        }

        if (search) {
            const searchLower = search.toLowerCase();
            filtered = filtered.filter(request =>
                request.utente.name.toLowerCase().includes(searchLower) ||
                request.domain_name.toLowerCase().includes(searchLower) ||
                request.action.toLowerCase().includes(searchLower)
            );
        }

        setFilteredRequests(filtered);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending': return "warning";
            case 'processing': return "info";
            case 'completed': return "success";
            case 'failed': return "danger";
            default: return "secondary";
        }
    };

    const getCountByStatus = (status) => {
        if (status === 'all') return requests.length;
        return requests.filter(item => item.status === status).length;
    };

    const getActionIcon = (action) => {
        const actionLower = action.toLowerCase();

        if (actionLower.includes('creazione') || actionLower.includes('create')) {
            return <PlusCircle size={20} className={`text-primary`} />;
        }
        if (actionLower.includes('rinnovo') || actionLower.includes('renewal')) {
            return <RefreshCw size={20} className={`text-success`} />;
        }
        if (actionLower.includes('autorinnovo') || actionLower.includes('auto renewal')) {
            return <ToggleLeft size={20} className={`text-info`} />;
        }
        return <Settings size={20} className={`text-secondary`} />;
    };

    const DetailPanel = ({ item }) => {
        const isFailed = item.status === 'failed';

        return (
            <div className="d-flex align-items-start">
                {isFailed && <AlertOctagon size={14} className="me-1 flex-shrink-0 mt-1" />}
                <div>
                    <div className={`${isFailed ? 'text-danger' : 'text-muted'} small`} style={{ fontSize: '0.85rem' }}>
                        {item.description || 'Nessuna descrizione disponibile.'}
                    </div>
                    {isFailed && item.response && (
                        <pre className="bg-light p-1 rounded small mb-0 mt-1"
                             style={{
                                 maxHeight: '40px',
                                 overflow: 'auto',
                                 fontSize: '0.75rem',
                             }}>
                            {JSON.stringify(item.response, null, 2)}
                        </pre>
                    )}
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center" style={{ minHeight: '305px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                        <p className="mt-2">Caricamento in corso...</p>
                    </div>
                </div>
            );
        }

        return (
            <>
                <CardTitle className="mb-4 d-flex justify-content-between align-items-center">
                    <span className="h4 mb-0">Richieste API</span>
                    {/*<Badge color="primary" pill>{filteredRequests.length}</Badge>*/}
                </CardTitle>

                <div className="mb-4">
                    <ButtonGroup className="w-100">
                        <Button color="primary" outline onClick={() => setFilter('all')} active={filter === 'all'}>
                            Tutte <Badge color={filter === 'all' ? "light" : "primary"} pill>{getCountByStatus('all')}</Badge>
                        </Button>
                        <Button color="primary" outline onClick={() => setFilter('pending')} active={filter === 'pending'}>
                            In Attesa <Badge color={filter === 'pending' ? "light" : "warning"} pill>{getCountByStatus('pending')}</Badge>
                        </Button>
                        <Button color="primary" outline onClick={() => setFilter('processing')} active={filter === 'processing'}>
                            In Elaborazione <Badge color={filter === 'processing' ? "light" : "info"} pill>{getCountByStatus('processing')}</Badge>
                        </Button>
                        <Button color="primary" outline onClick={() => setFilter('completed')} active={filter === 'completed'}>
                            Completate <Badge color={filter === 'completed' ? "light" : "success"} pill>{getCountByStatus('completed')}</Badge>
                        </Button>
                        <Button color="primary" outline onClick={() => setFilter('failed')} active={filter === 'failed'}>
                            Fallite <Badge color={filter === 'failed' ? "light" : "danger"} pill>{getCountByStatus('failed')}</Badge>
                        </Button>
                    </ButtonGroup>
                </div>

                <SimpleBar style={{ maxHeight: "305px" }}>
                    {requests.length === 0 ? (
                        <div className="text-center py-5">
                            <AlertCircle size={40} className="text-muted mb-3" />
                            <h5 className="text-muted">Nessuna Richiesta</h5>
                            <p className="text-muted mb-0">Non ci sono richieste API da visualizzare al momento.</p>
                        </div>
                    ) : (
                        <Table className="table-hover mb-0">
                            <thead className="bg-light" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                            <tr>
                                <th style={{ width: '25%', backgroundColor: 'white' }}>Azione/Dominio</th>
                                <th style={{ width: '45%', backgroundColor: 'white' }}>Descrizione</th>
                                <th style={{ width: '30%', backgroundColor: 'white' }}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredRequests.length > 0 ? (
                                filteredRequests.map((item, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">
                                            <div className="d-flex align-items-center">
                                                <div className={`bg-soft-${getStatusColor(item.status)} rounded-circle p-2 me-2`}>
                                                    {getActionIcon(item.action)}
                                                </div>
                                                <div>
                                                    <div style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>{item.action}</div>
                                                    <small className="text-muted" style={{ fontSize: '0.8rem' }}>{item.domain_name}</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <DetailPanel item={item} />
                                        </td>
                                        <td className="align-middle">
                                            <div className="text-end">
                                                <Badge color={getStatusColor(item.status)} pill className="mb-1" style={{ fontSize: '0.75rem' }}>
                                                    {item.status}
                                                </Badge>
                                                <div className="text-muted" style={{ fontSize: '0.75rem' }}>
                                                    <User size={12} className="me-1" />
                                                    {item.utente.name}
                                                </div>
                                                <div className="text-muted" style={{ fontSize: '0.75rem' }}>
                                                    <Calendar size={12} className="me-1" />
                                                    {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">
                                        <div className="text-center py-3">
                                            <AlertCircle size={24} className="text-muted mb-2" />
                                            <p className="text-muted mb-0">
                                                Nessuna richiesta trovata per il filtro selezionato.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    )}
                </SimpleBar>
            </>
        );
    };

    return (
        <Card className="shadow border-0">
            <CardBody>
                {error ? (
                    <Alert color="danger" className="d-flex align-items-center">
                        <AlertCircle size={24} className="me-2" />
                        <div>
                            {error}
                        </div>
                    </Alert>
                ) : (
                    renderContent()
                )}
            </CardBody>
        </Card>
    );
};

export default ApiRequestsTable;
