import axios from 'axios';
import config from '../config';

// Funzione per verificare se il token è scaduto
const isTokenExpired = () => {
    const expiresAt = localStorage.getItem('expiresAt');
    return expiresAt ? Date.now() >= parseInt(expiresAt) : true;
};

// Funzione per rimuovere i dati del token e reindirizzare al login
const handleTokenExpiration = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('authUser');
    window.location.href = '/login'; // Reindirizza l'utente al login
};

// Classe base per le chiamate API
class BaseAPIClient {
    constructor(baseURL) {
        this.api = axios.create({
            baseURL: baseURL || config.API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    get(url, params) {
        return this.api.get(url, { params });
    }

    post(url, data) {
        return this.api.post(url, data);
    }

    put(url, data) {
        return this.api.put(url, data);
    }

    delete(url, config) {
        return this.api.delete(url, { ...config });
    }
}

// Classe per le rotte protette
class ProtectedAPIClient extends BaseAPIClient {
    constructor(baseURL) {
        super(baseURL);
        this.setupInterceptors();
    }

    setupInterceptors() {
        this.api.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('token');
                if (token) {
                    if (isTokenExpired()) {
                        handleTokenExpiration(); // Gestione centralizzata della scadenza
                        return Promise.reject('Token expired'); // Rifiuta la richiesta
                    }
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        this.api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    handleTokenExpiration(); // Gestione centralizzata per il 401
                }
                return Promise.reject(error);
            }
        );
    }
}

// Classe per le rotte pubbliche
class PublicAPIClient extends BaseAPIClient {
    // Non ha bisogno di setup aggiuntivo
}

// Istanze delle classi
const protectedApi = new ProtectedAPIClient();
const publicApi = new PublicAPIClient();

export { protectedApi, publicApi };
