import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { User, Mail, Phone, MapPin, Building } from 'lucide-react';
import Select from 'react-select';
import styled from 'styled-components';
import apiCalls from "../../../../contexts/apiCall";

const IconWrapper = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: #007bff;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

const ErrorText = styled.div`
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
`;

const ContactForm = ({ contactType, contactData, onChange }) => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [localContactData, setLocalContactData] = useState(contactData);
    const [validationErrors, setValidationErrors] = useState({});

    const fetchContacts = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await apiCalls.contactsApi.getContactsByType(contactType);
            setContacts(response || []);
        } catch (err) {
            console.error(`Errore nel recupero dei contatti ${contactType}:`, err);
            setError(`Impossibile caricare i contatti ${contactType}. Riprova più tardi.`);
        } finally {
            setLoading(false);
        }
    }, [contactType]);

    useEffect(() => {
        fetchContacts();
    }, [fetchContacts]);

    useEffect(() => {
        setLocalContactData(contactData);
    }, [contactData]);

    const validateField = (name, value) => {
        if (!value || value.trim() === '') {
            return 'Questo campo è obbligatorio';
        }
        if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
            return 'Indirizzo email non valido';
        }
        return '';
    };

    const validateForm = (data) => {
        const errors = {};
        const requiredFields = ['firstname', 'lastname', 'email', 'voice', 'street', 'city', 'postal_code', 'country_code'];
        requiredFields.forEach(field => {
            const error = validateField(field, data[field]);
            if (error) {
                errors[field] = error;
            }
        });
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleContactSelect = (selectedOption) => {
        setSelectedContact(selectedOption);
        if (selectedOption && selectedOption.value) {
            const { firstname, lastname } = splitName(selectedOption.value.name);
            const updatedContact = {
                ...selectedOption.value,
                firstname,
                lastname,
            };
            setLocalContactData(updatedContact);
            if (validateForm(updatedContact)) {
                onChange(updatedContact);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...localContactData, [name]: value, contact_id: null }; // Rimuove contact_id
        setLocalContactData(updatedData);
        setValidationErrors(prev => ({ ...prev, [name]: validateField(name, value) }));
        if (validateForm(updatedData)) {
            onChange(updatedData);
        }
    };

    const splitName = (fullName) => {
        const nameParts = fullName.trim().split(' ');
        if (nameParts.length > 1) {
            return {
                lastname: nameParts[0],
                firstname: nameParts.slice(1).join(' ')
            };
        }
        return { firstname: fullName, lastname: '' };
    };

    const contactOptions = contacts.map(contact => ({
        value: contact,
        label: `${contact.name} (${contact.email || 'No email'})`
    }));

    return (
        <div className="p-3">
            <FormGroup>
                <Label for={`${contactType}-select`}>Seleziona un contatto esistente o inserisci nuovi dati</Label>
                <Select
                    id={`${contactType}-select`}
                    options={contactOptions}
                    value={selectedContact}
                    onChange={handleContactSelect}
                    isLoading={loading}
                    isDisabled={loading}
                    placeholder={loading ? "Caricamento contatti..." : "Seleziona un contatto"}
                />
                {error && <ErrorText>{error}</ErrorText>}
            </FormGroup>

            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for={`${contactType}-firstname`}>
                            <IconWrapper><User size={18} /></IconWrapper>
                            Nome
                        </Label>
                        <StyledInput
                            type="text"
                            name="firstname"
                            id={`${contactType}-firstname`}
                            value={localContactData.firstname || ''}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.firstname && <ErrorText>{validationErrors.firstname}</ErrorText>}
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for={`${contactType}-lastname`}>
                            <IconWrapper><User size={18} /></IconWrapper>
                            Cognome
                        </Label>
                        <StyledInput
                            type="text"
                            name="lastname"
                            id={`${contactType}-lastname`}
                            value={localContactData.lastname || ''}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.lastname && <ErrorText>{validationErrors.lastname}</ErrorText>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for={`${contactType}-email`}>
                            <IconWrapper><Mail size={18} /></IconWrapper>
                            Email
                        </Label>
                        <StyledInput
                            type="email"
                            name="email"
                            id={`${contactType}-email`}
                            value={localContactData.email || ''}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.email && <ErrorText>{validationErrors.email}</ErrorText>}
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for={`${contactType}-voice`}>
                            <IconWrapper><Phone size={18} /></IconWrapper>
                            Telefono
                        </Label>
                        <StyledInput
                            type="tel"
                            name="voice"
                            id={`${contactType}-voice`}
                            value={localContactData.voice || ''}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.voice && <ErrorText>{validationErrors.voice}</ErrorText>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for={`${contactType}-fax`}>
                            <IconWrapper><Phone size={18} /></IconWrapper>
                            Fax
                        </Label>
                        <StyledInput
                            type="tel"
                            name="fax"
                            id={`${contactType}-fax`}
                            value={localContactData.fax || ''}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for={`${contactType}-company`}>
                            <IconWrapper><Building size={18} /></IconWrapper>
                            Azienda
                        </Label>
                        <StyledInput
                            type="text"
                            name="company"
                            id={`${contactType}-company`}
                            value={localContactData.company || ''}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for={`${contactType}-street`}>
                    <IconWrapper><MapPin size={18} /></IconWrapper>
                    Indirizzo
                </Label>
                <StyledInput
                    type="text"
                    name="street"
                    id={`${contactType}-street`}
                    value={localContactData.street || ''}
                    onChange={handleInputChange}
                    required
                />
                {validationErrors.street && <ErrorText>{validationErrors.street}</ErrorText>}
            </FormGroup>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for={`${contactType}-city`}>Città</Label>
                        <StyledInput
                            type="text"
                            name="city"
                            id={`${contactType}-city`}
                            value={localContactData.city || ''}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.city && <ErrorText>{validationErrors.city}</ErrorText>}
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for={`${contactType}-postal_code`}>CAP</Label>
                        <StyledInput
                            type="text"
                            name="postal_code"
                            id={`${contactType}-postal_code`}
                            value={localContactData.postal_code || ''}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.postal_code && <ErrorText>{validationErrors.postal_code}</ErrorText>}
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for={`${contactType}-country_code`}>Codice Paese</Label>
                        <StyledInput
                            type="text"
                            name="country_code"
                            id={`${contactType}-country_code`}
                            value={localContactData.country_code || ''}
                            onChange={handleInputChange}
                            required
                        />
                        {validationErrors.country_code && <ErrorText>{validationErrors.country_code}</ErrorText>}
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
};

export default ContactForm;
