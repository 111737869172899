import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { motion } from 'framer-motion';
import Select from 'react-select';
import { Check } from 'lucide-react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { CompletionIndicator } from './StyledComponents';
import ContactForm from './ContactForm';
import NameserverForm from './NameserverForm';
import DNSSECForm from './DNSSECForm';
import RegistrationSummary from './RegistrationSummary';
import { isContactComplete } from './registrationUtils';

const RegistrationSteps = ({
                               activeStep,
                               activeTab,
                               formData,
                               setActiveTab,
                               handleInputChange,
                               handleContactChange,
                               handleNameserverChange,
                               handleDNSSECChange,
                               setFormData
                           }) => {
    const renderStepContent = () => {
        switch (activeStep) {
            case 1:
                return (
                    <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -50 }}>
                        <h5 className="mb-4">Informazioni sul Dominio</h5>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="domain_name">Nome del Dominio</Label>
                                    <Input
                                        type="text"
                                        name="domain_name"
                                        id="domain_name"
                                        value={`${formData.domain_name}.${formData.tld}`}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="subscription_period">Periodo (anni)</Label>
                                    <Input
                                        type="number"
                                        name="subscription_period"
                                        id="subscription_period"
                                        value={formData.subscription_period}
                                        onChange={handleInputChange}
                                        min="1"
                                        max="10"
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </motion.div>
                );
            case 2:
                return (
                    <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -50 }}>
                        <h5 className="mb-4">Informazioni di Contatto</h5>
                        <Nav tabs>
                            {['admin', 'billing', 'tech', 'org'].map((contactType) => (
                                <NavItem key={contactType}>
                                    <NavLink
                                        className={classnames({ active: activeTab === contactType })}
                                        onClick={() => setActiveTab(contactType)}
                                    >
                                        {contactType.charAt(0).toUpperCase() + contactType.slice(1)}
                                        {isContactComplete(contactType, formData.contacts) && (
                                            <CompletionIndicator>
                                                <Check size={12} />
                                            </CompletionIndicator>
                                        )}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {['admin', 'billing', 'tech', 'org'].map((contactType) => (
                                <TabPane tabId={contactType} key={contactType}>
                                    <ContactForm
                                        contactType={contactType}
                                        contactData={formData.contacts[contactType]}
                                        onChange={(data) => handleContactChange(contactType, data)}
                                    />
                                </TabPane>
                            ))}
                        </TabContent>
                    </motion.div>
                );
            case 3:
                return (
                    <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -50 }}>
                        <h5 className="mb-4">Configurazione Nameserver</h5>
                        <NameserverForm
                            nameservers={formData.nameservers}
                            onChange={handleNameserverChange}
                        />
                    </motion.div>
                );
            case 4:
                return (
                    <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -50 }}>
                        <h5 className="mb-4">Impostazioni Avanzate</h5>
                        <DNSSECForm
                            dnssecData={formData.dnssec}
                            onChange={handleDNSSECChange}
                        />
                        <Row className="mt-4">
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="renewal_mode">Modalità di Rinnovo</Label>
                                    <Select
                                        options={[
                                            { value: 'autoexpire', label: 'Auto Expire' },
                                            { value: 'autorenew', label: 'Auto Renew' }
                                        ]}
                                        value={{
                                            value: formData.renewal_mode,
                                            label: formData.renewal_mode === 'autorenew' ? 'Auto Renew' : 'Auto Expire'
                                        }}
                                        onChange={(selectedOption) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                renewal_mode: selectedOption.value
                                            }));
                                        }}
                                        placeholder="Seleziona modalità di rinnovo"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="whois_privacy">Privacy WHOIS</Label>
                                    <Select
                                        options={[
                                            { value: 'No', label: 'Disattivata' },
                                            { value: 'Yes', label: 'Attivata' }
                                        ]}
                                        value={{
                                            value: formData.whois_privacy,
                                            label: formData.whois_privacy === 'Yes' ? 'Attivata' : 'Disattivata'
                                        }}
                                        onChange={(selectedOption) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                whois_privacy: selectedOption.value
                                            }));
                                        }}
                                        placeholder="Seleziona privacy WHOIS"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </motion.div>
                );
            case 5:
                return (
                    <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -50 }}>
                        <h5 className="mb-4">Riepilogo Registrazione</h5>
                        <RegistrationSummary formData={formData} />
                        <FormGroup check className="mt-4 text-center">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="accept_registration_terms"
                                    // checked={formData.accept_registration_terms === 'Yes'}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        accept_registration_terms: e.target.checked ? 'Yes' : 'No'
                                    }))}
                                    required
                                />{' '}
                                Accetto i termini di registrazione
                            </Label>
                        </FormGroup>
                    </motion.div>
                );
            default:
                return null;
        }
    };

    return renderStepContent();
};

export default RegistrationSteps;
