import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Alert,
    Spinner,
    InputGroup,
    InputGroupText
} from 'reactstrap';
import {X, User, Mail, Shield, ToggleLeft, Search, DollarSign} from 'lucide-react';
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import apiCalls from "../../contexts/apiCall";
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required('Il nome è obbligatorio'),
    email: yup.string().email('Email non valida').required('L\'email è obbligatoria'),
    role: yup.string().required('Ruolo obbligatorio'),
    is_active: yup.boolean().required('Stato utente obbligatorio'),
    client_ids: yup.array().of(yup.number()).when('role', {
        is: 'cliente',
        then: yup.array().min(1, 'Seleziona almeno un profilo').required('Seleziona almeno un profilo'),
        otherwise: yup.array()
    }),
    price_list_id: yup.number().when('role', {
        is: 'cliente',
        then: yup.number().required('Seleziona un listino prezzi'),
        otherwise: yup.number().nullable()
    }),
});

const UserEdit = () => {
    const {id} = useParams();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        role: '',
        is_active: true,
        client_ids: [],
        price_list_id: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);
    const [priceLists, setPriceLists] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await apiCalls.usersActionsApi.getUserDetails(id);
                setFormData({
                    name: response.name,
                    email: response.email,
                    role: response.role,
                    is_active: response.is_active,
                    client_ids: response.client_ids,
                    price_list_id: response.price_list_id.toString(),
                });
            } catch (err) {
                console.error('Errore nel caricamento dei dati utente:', err);
                if (err.response && err.response.status === 404) {
                    setError('Utente non trovato. Verifica l\'ID e riprova.');
                } else {
                    setError('Si è verificato un errore durante il caricamento dei dati utente. Riprova più tardi.');
                }
            }
        };

        const fetchClients = async () => {
            try {
                const response = await apiCalls.clientiActionsApi.listlientiForUSer();
                setClients(response.data);
                setFilteredClients(response.data);
            } catch (err) {
                console.error('Errore nel caricamento dei clienti:', err);
                setError(prevError => prevError || 'Errore nel caricamento dei clienti');
            }
        };

        const fetchPriceLists = async () => {
            try {
                const response = await apiCalls.clientiActionsApi.getPriceLists();
                setPriceLists(response);
            } catch (err) {
                console.error('Errore nel caricamento dei listini prezzi:', err);
                setError(prevError => prevError || 'Errore nel caricamento dei listini prezzi');
            }
        };

        Promise.all([fetchUserData(), fetchClients(), fetchPriceLists()])
            .finally(() => setLoading(false));
    }, [id]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: name === 'is_active' ? value === 'true' : value
        }));
    };

    const handleClientSelection = useCallback((clientId) => {
        setFormData(prevData => {
            const updatedClientIds = prevData.client_ids.includes(clientId)
                ? prevData.client_ids.filter(id => id !== clientId)
                : [...prevData.client_ids, clientId];
            return {...prevData, client_ids: updatedClientIds};
        });
    }, []);

    const handleSearch = useCallback((e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filtered = clients.filter(client =>
            client.contact_name.toLowerCase().includes(searchTerm) ||
            (client.company && client.company.toLowerCase().includes(searchTerm))
        );
        setFilteredClients(filtered);
    }, [clients]);

    const columns = useMemo(() => [
        {
            name: 'Seleziona',
            cell: row => (
                <Input
                    type="checkbox"
                    checked={formData.client_ids.includes(row.id)}
                    onChange={() => handleClientSelection(row.id)}
                />
            ),
            width: '100px',
        },
        {
            name: 'Nome',
            selector: row => row.contact_name,
            sortable: true,
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
            cell: row => (row.company && row.company.trim()) || 'N/D',
        },
        {
            name: 'Numero di Domini',
            selector: row => Object.keys(row.admin_domains || {}).length,
            sortable: true,
        },
    ], [formData.client_ids, handleClientSelection]);

    const validateForm = () => {
        try {
            schema.validateSync(formData, {abortEarly: false});
            return {};
        } catch (err) {
            return err.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setError(Object.values(errors).join(", "));
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const userData = {
                ...formData,
                price_list_id: formData.role === 'cliente' ? parseInt(formData.price_list_id) : null
            };
            console.log(userData);
            await apiCalls.usersActionsApi.updateUser(id, userData);
            setSubmitStatus({ type: 'success', message: 'Utente aggiornato con successo' });
            setTimeout(() => navigate('/admin-users'), 2000);
        } catch (err) {
            console.error('Errore durante l\'aggiornamento dell\'utente:', err);
            setError(err.response?.data?.message || 'Si è verificato un errore durante l\'aggiornamento dell\'utente');
            setSubmitStatus({
                type: 'danger',
                message: 'Si è verificato un errore durante l\'aggiornamento dell\'utente. Riprova più tardi.'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/admin-users');
    };

    if (loading) {
        return (
            <div className="page-content d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                <Spinner color="primary"/>
            </div>
        );
    }

    if (error) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Alert color="danger">
                                {error}
                            </Alert>
                            <Button color="primary" onClick={() => navigate('/admin-users')}>
                                Torna alla Lista Utenti
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Utenti" breadcrumbItem="Modifica Utente"/>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <Card className="shadow">
                                <CardBody className="p-4">
                                    <h4 className="card-title mb-4">Modifica Utente</h4>
                                    {submitStatus && (
                                        <Alert color={submitStatus.type} className="mb-4">
                                            {submitStatus.message}
                                        </Alert>
                                    )}
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Label for="name">Nome</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <User size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Inserisci il nome"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <Mail size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Inserisci l'email"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="role">Ruolo</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <Shield size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="role"
                                                    name="role"
                                                    type="select"
                                                    value={formData.role}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Seleziona un ruolo</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="cliente">Cliente</option>
                                                    <option value="user">User</option>
                                                </Input>
                                            </InputGroup>
                                        </FormGroup>
                                        {formData.role === 'cliente' && (
                                            <>
                                                <FormGroup>
                                                    <Label for="client_ids">Profili</Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroupText>
                                                            <Search size={18}/>
                                                        </InputGroupText>
                                                        <Input
                                                            type="text"
                                                            placeholder="Cerca per nome o company"
                                                            value={searchTerm}
                                                            onChange={handleSearch}
                                                        />
                                                    </InputGroup>
                                                    <DataTable
                                                        columns={columns}
                                                        data={filteredClients}
                                                        fixedHeader
                                                        fixedHeaderScrollHeight="400px"
                                                        highlightOnHover
                                                        pointerOnHover
                                                        noHeader
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="price_list_id">Listino Prezzi</Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            <DollarSign size={18}/>
                                                        </InputGroupText>
                                                        <Input
                                                            id="price_list_id"
                                                            name="price_list_id"
                                                            type="select"
                                                            value={formData.price_list_id}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Seleziona un listino prezzi</option>
                                                            {priceLists.map(list => (
                                                                <option key={list.id} value={list.id}>
                                                                    {list.name}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </InputGroup>
                                                </FormGroup>
                                            </>
                                        )}
                                        <FormGroup>
                                            <Label for="is_active">Stato Utente</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <ToggleLeft size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="is_active"
                                                    name="is_active"
                                                    type="select"
                                                    value={formData.is_active.toString()}
                                                    onChange={handleChange}
                                                >
                                                    <option value="true">Attivo</option>
                                                    <option value="false">Inattivo</option>
                                                </Input>
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="d-flex justify-content-end mt-4">
                                            <Button type="button" color="light" onClick={handleCancel} className="me-2">
                                                <X size={18} className="me-1"/>
                                                Annulla
                                            </Button>
                                            <Button type="submit" color="primary" disabled={loading}>
                                                {loading ? <Spinner size="sm" className="me-1"/> : null}
                                                Aggiorna Utente
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserEdit;
