import React from "react";
import {Col, Container, Row} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import RenewalNotificationsNoAutoRenewalClienti from "./components/RenewalNotificationsNoAutoRenewalClienti";
import RenewalNotificationsClienti from "./components/RenewalNotificationsClienti";
import DomainOverviewClienti from "./components/DomainOverviewClienti";
import ClientRequestsClienti from "./components/ClientRequestsClienti";



const DashboardClienti = () => {
    document.title = "Dashboard | Whiteready - Pannello di gestione domini";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Whiteready" breadcrumbItem="Dashboard"/>
                    <Row>
                        <Col xl={4}>
                            <RenewalNotificationsNoAutoRenewalClienti/>
                        </Col>

                        <Col xl={4}>
                            <DomainOverviewClienti/>
                        </Col>

                        <Col xl={4}>
                            <RenewalNotificationsClienti/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2}>

                        </Col>
                        <Col xl={8}>
                            <ClientRequestsClienti/>
                        </Col>
                        <Col xl={2}>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardClienti;
