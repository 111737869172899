import styled from 'styled-components';
import { Card, FormGroup } from 'reactstrap';

export const StyledCard = styled(Card)`
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
`;

export const StepIndicator = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

export const Step = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.active ? '#007bff' : '#6c757d'};
    font-weight: ${props => props.active ? 'bold' : 'normal'};
`;

export const StepNumber = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${props => props.active ? '#007bff' : '#e9ecef'};
    color: ${props => props.active ? 'white' : '#6c757d'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

export const CompletionIndicator = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #28a745;
    color: white;
    margin-left: 5px;
`;

export const CenteredFormGroup = styled(FormGroup)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
