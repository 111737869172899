import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const DeleteUserModal = ({ isOpen, toggle, user, onConfirm }) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Conferma Cancellazione</ModalHeader>
        <ModalBody>
            Sei sicuro di voler cancellare l'utente {user?.name}?
            Questa azione non può essere annullata.
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={toggle}>
                Annulla
            </Button>
            <Button color="danger" onClick={onConfirm}>
                Conferma Cancellazione
            </Button>
        </ModalFooter>
    </Modal>
);

export default DeleteUserModal;
