import { protectedApi } from "../api";
import * as url from "../url_config";

const emailQueueApi = {
        listEmailQueue: async () => {
        try {
            const emailQueue = await protectedApi.get(url.GET_EMAIL_QUEUE);
            return emailQueue.data;
        } catch (error) {
            console.error('Failed to fetch email queue:', error);
            throw error;
        }
    }
};

export default emailQueueApi;
