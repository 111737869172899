import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import apiCalls from "../../../../contexts/apiCall";
import { Alert, Button, Card, CardBody, Spinner, ButtonGroup, UncontrolledTooltip } from "reactstrap";

const LineColumnArea = () => {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('monthly');

    useEffect(() => {
        fetchData();
    }, [viewMode]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await apiCalls.dashboardDataApi.graficoAndamentoDomini(viewMode);
            if (response && response.status === "success" && response.data && response.data.overview) {
                const formattedData = response.data.overview.map(item => ({
                    name: item.title,
                    type: item.type,
                    data: item.data
                }));
                setChartData(formattedData);
            } else {
                throw new Error("Dati non validi o vuoti");
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching overview data:", err);
            setError("Errore nel caricamento dei dati");
        } finally {
            setLoading(false);
        }
    };

    const getChartOptions = () => ({
        chart: {
            height: 450,
            type: 'line',
            stacked: false,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true
                },
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            dropShadow: {
                enabled: true,
                top: 3,
                left: 2,
                blur: 4,
                opacity: 0.1
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: [4, 4, 4]
        },
        title: {
            text: viewMode === 'monthly' ? 'Panoramica Domini Mensile' : 'Panoramica Domini Annuale',
            align: 'left',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#263238'
            }
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            hover: {
                size: 8
            }
        },
        xaxis: {
            categories: viewMode === 'monthly'
                ? ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
                : chartData[0]?.data.map((_, index) => (new Date().getFullYear() - 9 + index).toString()) || [],
            title: {
                text: viewMode === 'monthly' ? 'Mese' : 'Anno'
            }
        },
        yaxis: [
            {
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#008FFB' },
                labels: { style: { colors: '#008FFB' } },
                title: { text: "Nuovi Domini", style: { color: '#008FFB' } },
                tooltip: { enabled: true }
            },
            {
                seriesName: 'Totale Cumulativo Domini',
                opposite: true,
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#00E396' },
                labels: { style: { colors: '#00E396' } },
                title: { text: "Totale Cumulativo", style: { color: '#00E396' } },
            },
            {
                seriesName: 'Media Mobile',
                opposite: true,
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#FEB019' },
                labels: { style: { colors: '#FEB019' } },
                title: { text: viewMode === 'monthly' ? "Media Mobile 3 Mesi" : "Media Mobile 3 Anni", style: { color: '#FEB019' } },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " domini"
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    chart: {
                        height: 350
                    },
                    legend: {
                        position: 'bottom',
                        offsetX: 0
                    }
                }
            }
        ],
        theme: {
            mode: 'light',
            palette: 'palette1',
            monochrome: {
                enabled: false,
                color: '#255aee',
                shadeTo: 'light',
                shadeIntensity: 0.65
            },
        }
    });

    const downloadCSV = () => {
        if (chartData.length === 0) return;

        const headers = [viewMode === 'monthly' ? "Mese" : "Anno", ...chartData.map(series => series.name)];
        const csvData = [headers];

        const categories = viewMode === 'monthly'
            ? ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
            : chartData[0]?.data.map((_, index) => (new Date().getFullYear() - 9 + index).toString()) || [];

        for (let i = 0; i < categories.length; i++) {
            const row = [categories[i]];
            chartData.forEach(series => {
                row.push(series.data[i]);
            });
            csvData.push(row);
        }

        const csvContent = "data:text/csv;charset=utf-8,"
            + csvData.map(e => e.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `panoramica_domini_${viewMode}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="d-flex align-items-center justify-content-center" style={{ height: '300px' }}>
                    <Spinner color="primary" />
                    <p className="ml-2">Caricamento in corso...</p>
                </div>
            );
        }

        if (error || chartData.length === 0) {
            return (
                <Alert color="danger">
                    Errore nel caricamento dei dati. Si prega di riprovare più tardi.
                </Alert>
            );
        }

        return (
            <>
                <ReactApexChart
                    options={getChartOptions()}
                    series={chartData}
                    type="line"
                    height={450}
                />
            </>
        );
    };

    return (
        <Card className="shadow-sm">
            <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 mr-2">Andamento Domini</h5>
                        <span id="infoIcon" style={{ cursor: 'pointer', color: '#17a2b8', fontSize: '18px', marginLeft:'10px' }}>ⓘ</span>
                        <UncontrolledTooltip placement="right" target="infoIcon">
                            <strong>Come leggere il grafico:</strong><br />
                            - Le barre blu rappresentano i nuovi domini registrati per {viewMode === 'monthly' ? 'mese' : 'anno'}.<br />
                            - La linea verde mostra il totale cumulativo dei domini nel tempo.<br />
                            - La linea arancione indica la media mobile su {viewMode === 'monthly' ? '3 mesi' : '3 anni'},
                            utile per identificare tendenze a lungo termine.<br />
                            Usa i pulsanti sopra il grafico per passare dalla vista mensile a quella annuale.
                        </UncontrolledTooltip>
                    </div>
                    <ButtonGroup>
                        <Button color={viewMode === 'monthly' ? 'primary' : 'secondary'} onClick={() => setViewMode('monthly')}>Mensile</Button>
                        <Button color={viewMode === 'yearly' ? 'primary' : 'secondary'} onClick={() => setViewMode('yearly')}>Annuale</Button>
                    </ButtonGroup>
                </div>
                {renderContent()}
            </CardBody>
        </Card>
    );
};

export default LineColumnArea;
