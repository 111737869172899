import React from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import DataTable from 'react-data-table-component';

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Data non valida' : date.toLocaleString();
};

const clientColumns = [
    {
        name: 'Nome',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Azienda',
        selector: row => row.company || 'N/A',
        sortable: true,
    },
    {
        name: 'Numero di Domini Admin',
        selector: row => row.admin_domains_count,
        sortable: true,
    },
];

const ExpandedUserComponent = ({ data }) => (
    <Card className="m-3 border-0 shadow-sm">
        <CardBody>
            <h5 className="mb-4 text-primary">Informazioni Aggiuntive</h5>
            <Row>
                <Col md={4} className="mb-3">
                    <strong className="text-muted">Data di Registrazione:</strong> <br />
                    {formatDate(data.created_at)}
                </Col>
                <Col md={4} className="mb-3">
                    <strong className="text-muted">Ultimo Accesso:</strong> <br />
                    {formatDate(data.last_login)}
                </Col>
                <Col md={4} className="mb-3">
                    <strong className="text-muted">Ruolo:</strong> <br />
                    {data.role || 'N/A'}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={4}>
                    <strong className="text-muted">Listino Prezzi associato:</strong> <br />
                    {data.nome_listino}
                </Col>
            </Row>
            {data.clients && data.clients.length > 0 && (
                <>
                    <h6 className="mt-4 mb-3 text-primary">Profili Associati</h6>
                    <div style={{height: '300px'}}>
                        <DataTable
                            columns={clientColumns}
                            data={data.clients}
                            fixedHeader
                            fixedHeaderScrollHeight="250px"
                            highlightOnHover
                            dense
                            responsive
                            subHeaderWrap
                            noHeader
                        />
                    </div>
                </>
            )}
        </CardBody>
    </Card>
);

export default ExpandedUserComponent;
