import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Card, CardBody } from 'reactstrap';
import { Plus, Trash2, Server, Package } from 'lucide-react';
import styled from 'styled-components';
import apiCalls from "../../../../contexts/apiCall";

const StyledCard = styled(Card)`
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0,0,0,.1);
margin-bottom: 0.5rem;
`;

const StyledCardBody = styled(CardBody)`
padding: 0.75rem;
`;

const StyledInput = styled(Input)`
border-radius: 4px;
border: 1px solid #ced4da;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

&:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
`;

const IconWrapper = styled.span`
display: inline-flex;
align-items: center;
justify-content: center;
width: 24px;
height: 24px;
margin-right: 8px;
color: #007bff;
`;

const ActionButton = styled(Button)`
padding: 0.375rem 0.75rem;
font-size: 0.875rem;
`;

const RemoveButton = styled(ActionButton)`
height: 38px;
`;

const CompactLabel = styled(Label)`
margin-bottom: 0.25rem;
`;

// Stili aggiornati per il modal centrato
const StyledModal = styled(Modal)`
&&& {
    .modal-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
    width: 95%;
    max-width: 800px;
}

.modal-content {
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}
}
`;

const PackageButton = styled(Button)`
height: 60px;
white-space: normal;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
margin-bottom: 10px;
padding: 10px;
line-height: 1.2;
border-radius: 6px;
transition: all 0.2s ease-in-out;

&:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
`;

const MIN_NAMESERVERS = 2;
const MAX_NAMESERVERS = 5;

const NameserverForm = ({ nameservers, onChange }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [predefinedPackages, setPredefinedPackages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        validateNameservers();
    }, [nameservers]);

    useEffect(() => {
        fetchPredefinedPackages();
    }, []);

    const fetchPredefinedPackages = async () => {
        setLoading(true);
        try {
            const response = await apiCalls.settingApi.listNameserverPack();
            setPredefinedPackages(response);
        } catch (err) {
            setError("Errore durante il caricamento dei pacchetti predefiniti.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    const validateNameservers = () => {
        if (nameservers.length < MIN_NAMESERVERS) {
            setError(`È necessario inserire almeno ${MIN_NAMESERVERS} nameserver.`);
        } else {
            setError('');
        }
    };

    const handleNameserverChange = (index, field, value) => {
        const updatedNameservers = nameservers.map((ns, i) =>
            i === index ? { ...ns, [field]: value } : ns
        );
        onChange(updatedNameservers);
    };

    const addNameserver = () => {
        if (nameservers.length < MAX_NAMESERVERS) {
            onChange([...nameservers, { priority: '', fqdn: '', ip_address: '' }]);
        } else {
            setError(`Non è possibile aggiungere più di ${MAX_NAMESERVERS} nameserver.`);
        }
    };

    const removeNameserver = (index) => {
        if (nameservers.length > MIN_NAMESERVERS) {
            const updatedNameservers = nameservers.filter((_, i) => i !== index);
            onChange(updatedNameservers);
        } else {
            setError(`Non è possibile rimuovere. È necessario mantenere almeno ${MIN_NAMESERVERS} nameserver.`);
        }
    };

    const applyPredefinedPackage = (selectedPackage) => {
        if (selectedPackage.nameservers.length >= MIN_NAMESERVERS && selectedPackage.nameservers.length <= MAX_NAMESERVERS) {
            onChange(selectedPackage.nameservers);
            setModalOpen(false);
        } else {
            setError(`Il pacchetto selezionato deve contenere tra ${MIN_NAMESERVERS} e ${MAX_NAMESERVERS} nameserver.`);
        }
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0">
                    <IconWrapper><Server size={18} /></IconWrapper>
                    Configurazione Nameserver
                </h5>
                <div>
                    <ActionButton color="primary" onClick={addNameserver} className="me-2" disabled={nameservers.length >= MAX_NAMESERVERS}>
                        <Plus size={16} className="me-1" /> Aggiungi Nameserver
                    </ActionButton>
                    <ActionButton color="secondary" onClick={toggleModal}>
                        <Package size={16} className="me-1" /> Pacchetti Predefiniti
                    </ActionButton>
                </div>
            </div>
            {error && <Alert color="danger" className="mb-3">{error}</Alert>}
            {nameservers.map((ns, index) => (
                <StyledCard key={index}>
                    <StyledCardBody>
                        <Row className="align-items-center">
                            <Col md={2}>
                                <FormGroup className="mb-0">
                                    <CompactLabel for={`nameserver-priority-${index}`}>Priorità</CompactLabel>
                                    <StyledInput
                                        type="number"
                                        id={`nameserver-priority-${index}`}
                                        value={ns.priority}
                                        onChange={(e) => handleNameserverChange(index, 'priority', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup className="mb-0">
                                    <CompactLabel for={`nameserver-fqdn-${index}`}>FQDN</CompactLabel>
                                    <StyledInput
                                        type="text"
                                        id={`nameserver-fqdn-${index}`}
                                        value={ns.fqdn}
                                        onChange={(e) => handleNameserverChange(index, 'fqdn', e.target.value)}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup className="mb-0">
                                    <CompactLabel for={`nameserver-ip-${index}`}>Indirizzo IP</CompactLabel>
                                    <StyledInput
                                        type="text"
                                        id={`nameserver-ip-${index}`}
                                        value={ns.ip_address}
                                        onChange={(e) => handleNameserverChange(index, 'ip_address', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup className="mb-0">
                                    <CompactLabel>&nbsp;</CompactLabel>
                                    <RemoveButton color="danger" onClick={() => removeNameserver(index)} block>
                                        <Trash2 size={16} className="me-1" /> Rimuovi
                                    </RemoveButton>
                                </FormGroup>
                            </Col>
                        </Row>
                    </StyledCardBody>
                </StyledCard>
            ))}

            <StyledModal isOpen={modalOpen} toggle={toggleModal} centered={true}>
                <ModalHeader toggle={toggleModal}>
                    Seleziona un Pacchetto Nameserver
                </ModalHeader>
                <ModalBody className="py-4">
                    {loading ? (
                        <div className="text-center p-3">
                            Caricamento pacchetti...
                        </div>
                    ) : (
                        <Row className="g-3">
                            {predefinedPackages.map((pkg) => (
                                <Col md={4} key={pkg.id}>
                                    <PackageButton
                                        color="primary"
                                        outline
                                        className="w-100"
                                        onClick={() => applyPredefinedPackage(pkg)}
                                    >
                                        {pkg.name}
                                    </PackageButton>
                                </Col>
                            ))}
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </StyledModal>
        </div>
    );
};

export default NameserverForm;
