import React, {useEffect, useState} from "react";
import SimpleBar from "simplebar-react";
import {
    Alert,
    Card,
    CardBody,
    CardTitle,
    Spinner,
    ButtonGroup,
    Button,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    FormGroup
} from "reactstrap";
import {Calendar, Clock, AlertCircle, RefreshCw, CheckCircle, ToggleLeft} from 'lucide-react';
import apiCalls from "../../../../contexts/apiCall";
import {useDashboardUpdate} from "../contexts/DashboardUpdateContext";

const RenewalNotificationsNoAutoRenewal = () => {
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [renewalYears, setRenewalYears] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('30');
    const [renewalAlert, setRenewalAlert] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState({});
    const [disabledButtonsAutoRenew, setdisabledButtonsAutoRenew] = useState({});
    const [confirmModal, setConfirmModal] = useState({isOpen: false, type: null, domainName: null, currentType: null});
    const {triggerUpdate} = useDashboardUpdate();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        filterNotifications(filter, notifications);
    }, [filter, notifications]);

    useEffect(() => {
        if (renewalAlert) {
            const timer = setTimeout(() => {
                setRenewalAlert(null);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [renewalAlert]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await apiCalls.dashboardDataApi.notificheRinnoviNoRenewal();
            if (Array.isArray(data.original.data.notifications) && data.original.data.notifications.length > 0) {
                const validNotifications = data.original.data.notifications.filter(item => parseInt(item.time) > 0);
                setNotifications(validNotifications);
                filterNotifications('30', validNotifications);
            } else {
                setNotifications([]);
                setFilteredNotifications([]);
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching renewal notifications:", err);
            setError("Errore nel caricamento dei dati");
        } finally {
            setLoading(false);
        }
    };

    const filterNotifications = (selectedFilter, notificationsToFilter) => {
        let filtered;
        switch (selectedFilter) {
            case '30':
                filtered = notificationsToFilter.filter(item => parseInt(item.time) > 0 && parseInt(item.time) <= 30);
                break;
            case '60':
                filtered = notificationsToFilter.filter(item => parseInt(item.time) > 30 && parseInt(item.time) <= 60);
                break;
            case 'all':
            default:
                filtered = notificationsToFilter.filter(item => parseInt(item.time) > 0);
                break;
        }
        setFilteredNotifications(filtered);
    };

    const getStatusColor = (days) => {
        if (days <= 30) return "danger";
        if (days <= 60) return "warning";
        return "info";
    };

    const handleRenewal = (domainName) => {
        setConfirmModal({isOpen: true, type: 'renewal', domainName});
    };

    const handleToggleRenewalType = (domainName, currentType) => {
        setConfirmModal({isOpen: true, type: 'toggle', domainName, currentType});
    };

    const confirmAction = async () => {
        const {type, domainName, currentType} = confirmModal;
        setConfirmModal({isOpen: false, type: null, domainName: null, currentType: null});

        if (type === 'renewal') {
            try {
                const response = await apiCalls.dominiApi.renewDomain(domainName, renewalYears);

                if (response.status === 'success') {
                    setRenewalAlert({
                        type: 'success',
                        message: `La richiesta di rinnovo per il dominio ${domainName} è stata inviata con successo.`
                    });

                    setDisabledButtons(prev => ({...prev, [domainName]: true}));

                    // Trigger update after successful renewal
                    triggerUpdate();

                    await fetchData();
                } else {
                    throw new Error(response.message || 'Errore durante il rinnovo del dominio');
                }
            } catch (error) {
                console.error(`Errore nel rinnovo del dominio ${domainName}:`, error);
                setRenewalAlert({
                    type: 'danger',
                    message: `Si è verificato un errore durante la richiesta di rinnovo per il dominio ${domainName}: ${error.message}`
                });
            }
        } else if (type === 'toggle') {
            try {
                const response = await apiCalls.dominiApi.renewalModeDomain(domainName, 'autorenew');

                if (response.status === 'success') {
                    setRenewalAlert({
                        type: 'success',
                        message: `La richiesta di cambio modalità di rinnovo per il dominio ${domainName} è stata inoltrata con successo. Il dominio passerà alla modalità di autorinnovo.`
                    });

                    setdisabledButtonsAutoRenew(prev => ({...prev, [domainName]: true}));

                    // Trigger update after successful renewal
                    triggerUpdate();

                    await fetchData();
                } else {
                    throw new Error(response.message || 'Errore durante il rinnovo del dominio');
                }
            } catch (error) {
                console.error(`Errore nel rinnovo del dominio ${domainName}:`, error);
                setRenewalAlert({
                    type: 'danger',
                    message: `Si è verificato un errore durante la richiesta di rinnovo per il dominio ${domainName}: ${error.message}`
                });
            }
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center"
                     style={{minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div>
                        <Spinner color="primary" style={{width: '3rem', height: '3rem'}}/>
                        <p className="mt-2">Caricamento in corso...</p>
                    </div>
                </div>
            );
        }

        if (error) {
            return (
                <Alert color="danger" className="d-flex align-items-center">
                    <AlertCircle size={24} className="me-2"/>
                    <div>{error}</div>
                </Alert>
            );
        }

        if (filteredNotifications.length === 0) {
            return (
                <Alert color="info" className="d-flex align-items-center">
                    <AlertCircle size={24} className="me-2"/>
                    <div>
                        Non ci sono domini da rinnovare nel periodo selezionato. Tutti i tuoi domini sono attualmente in
                        regola!
                    </div>
                </Alert>
            );
        }


        return (
            <>
                {renewalAlert && (
                    <Alert color={renewalAlert.type} className="d-flex align-items-center mb-3">
                        <CheckCircle size={24} className="me-2"/>
                        <div>{renewalAlert.message}</div>
                    </Alert>
                )}
                <CardTitle className="mb-4 d-flex justify-content-between align-items-center">
                    <span className="h4 mb-0">Notifiche Rinnovi senza Autorinnovo</span>
                    <Badge color="primary" pill>{filteredNotifications.length}</Badge>
                </CardTitle>
                <ButtonGroup className="mb-4 w-100">
                    <Button color="primary" outline onClick={() => setFilter('all')} active={filter === 'all'}>
                        Tutti
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('30')} active={filter === '30'}>
                        30 giorni
                    </Button>
                    <Button color="primary" outline onClick={() => setFilter('60')} active={filter === '60'}>
                        60 giorni
                    </Button>
                </ButtonGroup>
                <SimpleBar style={{maxHeight: "305px"}}>
                    {filteredNotifications.map((item, key) => (
                        <Card key={key} className="mb-3 shadow-sm">
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div
                                            className={`bg-soft-${getStatusColor(parseInt(item.time))} rounded-circle p-2 me-3`}>
                                            <Calendar size={15}
                                                      className={`text-${getStatusColor(parseInt(item.time))}`}/>
                                        </div>
                                        <div>
                                            <h5 className="mb-1">{item.name} - {item.domainName}</h5>
                                            <p className="text-muted mb-0">{item.desc}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Badge color={getStatusColor(parseInt(item.time))} pill
                                               className="d-flex align-items-center me-3">
                                            <Clock size={14} className="me-1"/>
                                            {item.time} giorni
                                        </Badge>
                                        <Button
                                            color="success"
                                            size="sm"
                                            onClick={() => handleRenewal(item.domainName)}
                                            disabled={disabledButtons[item.domainName] || item.disabled}
                                            className="me-2"
                                        >
                                            <RefreshCw size={14} className="me-1"/>
                                            Rinnova
                                        </Button>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            onClick={() => handleToggleRenewalType(item.domainName)}
                                            disabled={disabledButtonsAutoRenew[item.domainName] || item.disabled}
                                        >
                                            <>
                                                <ToggleLeft size={14} className="me-1"/>
                                                Attiva Autorinnovo
                                            </>

                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </SimpleBar>
            </>
        );
    };

    return (
        <Card className="shadow border-0">
            <CardBody>
                {renderContent()}
            </CardBody>
            <Modal isOpen={confirmModal.isOpen}
                   toggle={() => setConfirmModal({isOpen: false, type: null, domainName: null, currentType: null})}>
                <ModalHeader
                    toggle={() => setConfirmModal({isOpen: false, type: null, domainName: null, currentType: null})}>
                    Conferma {confirmModal.type === 'renewal' ? 'Rinnovo' : 'Cambio Tipo Rinnovo'}
                </ModalHeader>
                <ModalBody>
                    {confirmModal.type === 'renewal' ? (
                        <>
                            <p>Sei sicuro di voler rinnovare il dominio {confirmModal.domainName}?</p>
                            <FormGroup>
                                <Label for="renewalYears">Anni di rinnovo</Label>
                                <Input
                                    type="number"
                                    name="renewalYears"
                                    id="renewalYears"
                                    value={renewalYears}
                                    onChange={(e) => setRenewalYears(parseInt(e.target.value))}
                                    min="1"
                                    max="10"
                                />
                            </FormGroup>
                        </>
                    ) : (
                        <span
                            dangerouslySetInnerHTML={{__html: `Sei sicuro di voler ${confirmModal.currentType === 'autoexpire' ? 'attivare' : 'disattivare'} l'autorinnovo per il dominio <strong>${confirmModal.domainName}</strong>?`}}/>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModal({
                        isOpen: false,
                        type: null,
                        domainName: null,
                        currentType: null
                    })}>
                        Annulla
                    </Button>
                    <Button color="primary" onClick={confirmAction}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>
        </Card>
    );
};

export default RenewalNotificationsNoAutoRenewal;
