import {protectedApi} from "../api";
import * as url from "../url_config";

const dominiApi = {
    checkDominio: async (domain, tld) => {
        try {

            const dominioData = await protectedApi.post(url.CHECK_DOMINIO, {domain, tld});
            return dominioData.data;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            throw error;
        }
    },
    getTldList: async () => {
        try {
            const tldList = await protectedApi.get(url.GET_TLD_LIST);
            return tldList.data;
        } catch (error) {
            console.error('Failed to fetch TLD list:', error);
            throw error;
        }
    },
    decryptDomainData: async (encryptedData) => {
        try {
            const response = await protectedApi.post(url.DECRYPT_DOMAIN_DATA, { encryptedData });
            if (response.data) {
                return response.data;
            } else {
                throw new Error(response.data.message || 'Errore nella decifratura dal backend');
            }
        } catch (error) {
            console.error('Errore nella richiesta di decifratura al backend:', error);
            throw error;
        }
    },
    renewDomain: async (domainName, renewalYears) => {
        try {
            const id = JSON.parse(localStorage.getItem('authUser')).id;
            const renewalData = await protectedApi.post(url.RENEW_DOMAIN, {domainName, renewalYears, id});
            return renewalData.data;
        } catch (error) {
            console.error('Failed to renew domain:', error);
            throw error;
        }
    },
    renewalModeDomain: async (domainName, mode) => {
        try {
            const id = JSON.parse(localStorage.getItem('authUser')).id;
            const renewalData = await protectedApi.post(url.RENEWAL_MODE_DOMAIN, {domainName, mode, id});
            return renewalData.data;
        } catch (error) {
            console.error('Failed to renew domain:', error);
            throw error;
        }
    },



};

export default dominiApi;
