import React from 'react';
import { Link } from 'react-router-dom';
import { PlusCircle, RefreshCw, Settings } from 'lucide-react';
import './QuickAccessCss.css';

const QuickAccessItem = ({ icon: Icon, title, link }) => (
    <Link to={link} className="quick-access-item">
        <Icon size={20} />
        <span>{title}</span>
    </Link>
);

const QuickAccess = () => {
    const quickAccessItems = [
        { icon: PlusCircle, title: "Registra dominio", link: "/domini" },
        { icon: RefreshCw, title: "Lista domini", link: "/manage-domini" },
        { icon: Settings, title: "Gestisci DNS", link: "/manage-domini" },
    ];

    return (
        <div className="quick-access-card">
            <h3 className="quick-access-title">Accesso Rapido</h3>
            <div className="quick-access-content">
                {quickAccessItems.map((item, index) => (
                    <QuickAccessItem key={index} {...item} />
                ))}
            </div>
        </div>
    );
};

export default QuickAccess;
