import React from 'react';
import { Input, Button } from "reactstrap";
import { Search, XCircle } from 'lucide-react';

const UserFilters = ({ filters, setFilters }) => {
    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const resetFilters = () => {
        setFilters({ name: '', email: '', role: '' });
    };

    return (
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
            <div className="d-flex flex-wrap align-items-center mb-2 mb-md-0">
                <Search size={20} className="text-muted me-2" />
                <Input
                    type="text"
                    name="name"
                    placeholder="Nome..."
                    value={filters.name}
                    onChange={handleFilterChange}
                    className="form-control-sm me-2 mb-2 mb-md-0"
                    style={{ width: '150px' }}
                />
                <Input
                    type="text"
                    name="email"
                    placeholder="Email..."
                    value={filters.email}
                    onChange={handleFilterChange}
                    className="form-control-sm me-2 mb-2 mb-md-0"
                    style={{ width: '150px' }}
                />
                <Input
                    type="text"
                    name="role"
                    placeholder="Ruolo..."
                    value={filters.role}
                    onChange={handleFilterChange}
                    className="form-control-sm mb-2 mb-md-0"
                    style={{ width: '150px' }}
                />
            </div>
            <Button color="secondary" size="sm" onClick={resetFilters}>
                <XCircle size={14} className="me-1" /> Azzera filtri
            </Button>
        </div>
    );
};

export default UserFilters;
