import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Spinner } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import apiCalls from "../../../../../contexts/apiCall";
import DomainFilters from './DomainFilters';
import { getDomainTableColumns } from './DomainTableColumns';

const DomainTable = ({ domains, handleAction, formatDate, isExpiringWithinTwoMonths }) => {
    const navigate = useNavigate();
    const [filteredDomains, setFilteredDomains] = useState(domains);
    const [loading, setLoading] = useState(true);
    const [tldOptions, setTldOptions] = useState([]);

    useEffect(() => {
        fetchTldList();
        setTimeout(() => setLoading(false), 1000);
    }, []);

    const fetchTldList = async () => {
        try {
            const response = await apiCalls.dominiApi.getTldList();
            setTldOptions(response.map(tld => ({ value: tld, label: tld })));
        } catch (error) {
            console.error('Error fetching TLD list:', error);
        }
    };

    const handleViewDetails = (domain) => {
        navigate(`/dettagli-domini/${domain.id}`);
    };

    const ExpandedComponent = ({ data }) => (
        <Card className="m-3">
            <CardBody>
                <h5 className="mb-4">Informazioni di Contatto</h5>
                <Row className="d-flex flex-nowrap overflow-auto">
                    {data.contacts.map((contact, index) => (
                        <Card key={index} className="shadow-sm mx-2" style={{ width: '300px', backgroundColor: getContactCardBackground(index) }}>
                            <CardBody className="d-flex flex-column">
                                <h6 className="text-primary mb-2">{contact.type.toUpperCase()} Contact</h6>
                                <div className="mb-2">
                                    <strong>Nome:</strong> {contact.name || 'N/A'}
                                </div>
                                <div className="mb-2">
                                    <strong>Email:</strong> {contact.email || 'N/A'}
                                </div>
                                <div className="mb-2">
                                    <strong>Telefono:</strong> {contact.voice || 'N/A'}
                                </div>
                                <div className="mb-2">
                                    <strong>Indirizzo:</strong> {formatAddress(contact)}
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </Row>
            </CardBody>
        </Card>
    );

    const formatAddress = (contact) => {
        const parts = [
            contact.street,
            contact.city,
            contact.postal_code,
            contact.country_code
        ].filter(Boolean);
        return parts.join(', ') || 'N/A';
    };

    const getContactCardBackground = (index) => {
        const colors = ['#f8f9fa', '#e9ecef', '#f1f3f5', '#dee2e6'];
        return colors[index % colors.length];
    };

    const columns = getDomainTableColumns(handleAction, handleViewDetails, formatDate);

    const conditionalRowStyles = [
        {
            when: row => isExpiringWithinTwoMonths(row.expires_at),
            style: {
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
            },
        },
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '400px' }}>
                <Spinner color="primary" />
            </div>
        );
    }

    return (
        <>
            <DomainFilters
                domains={domains}
                setFilteredDomains={setFilteredDomains}
                tldOptions={tldOptions}
                isExpiringWithinTwoMonths={isExpiringWithinTwoMonths}
            />

            <DataTable
                columns={columns}
                data={filteredDomains}
                pagination
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                expandableIcon={{
                    collapsed: <ChevronDown size={24} />,
                    expanded: <ChevronUp size={24} />
                }}
                conditionalRowStyles={conditionalRowStyles}
                progressPending={loading}
                progressComponent={
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '400px' }}>
                        <Spinner color="primary" />
                    </div>
                }
                noDataComponent={
                    <div className="text-center py-4">
                        <h4>Nessun dominio trovato</h4>
                        <p>Prova a modificare i filtri di ricerca</p>
                    </div>
                }
            />
        </>
    );
};

export default DomainTable;
