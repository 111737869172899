import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import sidebarData from "./SidebarData";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../../components/Common/withRouter";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import {jwtDecode} from "jwt-decode"; // Decodifica il token JWT

const Sidebar = (props) => {
  const ref = useRef();

  // Recupera il ruolo dell'utente dal token JWT
  const getUserRole = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    const decodedToken = jwtDecode(token);
    return decodedToken.role;
  };

  const userRole = getUserRole(); // Ottieni il ruolo dell'utente

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // Mark parent as active
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // Expand parent
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("mm-active"); // Mark ancestor as active
          parent3.childNodes[0].classList.add("mm-active"); // Mark ancestor's first child as active
        }
      }
      scrollElement(item);
    }
  }, []);

  const removeActivation = items => {
    let activeItems = items.filter(item => item.classList.contains("active"));
    activeItems.forEach(item => {
      item.classList.remove("active");
      let parent = item.parentElement;
      if (parent) {
        parent.classList.remove("mm-active");
        let parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove("mm-show");
          let parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active");
          }
        }
      }
    });
  };

  const activeMenu = useCallback(() => {
    let items = document.getElementsByClassName("mm-active");
    removeActivation([...items]);

    let links = document.getElementsByClassName("side-nav-link-ref");
    let matchingMenuItem = null;
    for (let i = 0; i < links.length; i++) {
      if (props.router.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu-item");
    activeMenu();
  }, []);

  useEffect(() => {
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    setTimeout(() => {
      if (item) {
        const currentPosition = item.offsetTop;
        if (currentPosition > window.innerHeight) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }, 300);
  }

  return (
      <React.Fragment>
        <div className="vertical-menu">
          <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu-item">
                {(sidebarData || [])
                    .filter(item => {
                      // Mostra solo il menu Admin agli utenti con ruolo admin
                      if (item.isAdmin && userRole !== "admin") {
                        return false;
                      }
                      return true;
                    })
                    .map((item, key) => (
                        <React.Fragment key={key}>
                          {item.isMainMenu ? (
                              <li className="menu-title">{props.t(item.label)}</li>
                          ) : (
                              <li key={key}>
                                <Link
                                    to={item.url ? item.url : "/#"}
                                    className={
                                      item.issubMenubadge || item.isHasArrow ? " " : "has-arrow"
                                    }
                                >
                                  <i className={item.icon} style={{ marginRight: "5px" }}></i>
                                  {item.issubMenubadge && (
                                      <span className={"badge rounded-pill float-end " + item.bgcolor}>
                              {" "}
                                        {item.badgeValue}{" "}
                            </span>
                                  )}
                                  <span>{props.t(item.label)}</span>
                                </Link>
                                {item.subItem && (
                                    <ul className="sub-menu">
                                      {item.subItem.map((subItem, subKey) => (
                                          <li key={subKey}>
                                            <Link to={subItem.link}>{props.t(subItem.sublabel)}</Link>
                                          </li>
                                      ))}
                                    </ul>
                                )}
                              </li>
                          )}
                        </React.Fragment>
                    ))}
              </ul>
            </div>
          </SimpleBar>
        </div>
      </React.Fragment>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Sidebar));
